import React, { useState, useEffect } from 'react';
import { Box, Button, Container, Grid, Typography, Card, InputAdornment, Backdrop, CircularProgress, TextField, Stack, Autocomplete } from '@mui/material';
import SearchIcon from '../../icons/Search';
import PlusIcon from '../../icons/Plus';
import api from '../../lib/axios';
import { ConfirmAlert } from '../../customControls/confirmAlert';
import BookEnterpriseCategoryTable from './BookEnterpriseCategoryTable';
import SnackbarProperties from '../../components/SnackbarProperties';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import BookListTable from './BookListTable';
import { CategoryAlert } from '../Sequence/CategoryAlert';

export const BookEnterpriseCategoryList = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [arrayData, setArrayData] = useState([]);
  const [query, setQuery] = useState("");
  const [arrayBook, setArrayBook] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("0");
  const [selectedCategory, setSelectedCategory] = useState({ ebcID: "0", categoryName: "All" });
  const [selectedBookId, setSelectedBookId] = useState("");
  const [categoryDialogueShouldOpen, setCategoryDialogueShouldOpen] = useState(false);
  const [objDeleteConfirmation, seObjDeleteConfirmation] = useState({
    deleteMessage: "Are you sure you want to delete?",
    deleteConfirmationShouldOpen: false,
    selectedDeleteId: "",
    deleteType: ""
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setIsLoading(true);
    api.get('/apis/EnterpriseBookCategores/byEnterprise/' + localStorage.getItem("enterpriseID"))
      .then((res) => {
        if (res.data.success) {
          const resData = res.data.data;
          var array = [{ ebcID: "0", categoryName: "All" }]
          var arrayCategory = [...array, ...resData]
          setArrayData(arrayCategory);
          var categoryId = localStorage.getItem("selectedChannelCategoryId");
          if (categoryId == null || categoryId == "") {
            categoryId = "0"
          }
          setSelectedCategoryId(categoryId)
          var selectedCategory = arrayCategory.find(obj => obj.ebcID == categoryId)
          setSelectedCategory(selectedCategory)
          getBookByCategory(categoryId)
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBookByCategory = (categoryId) => {
    setIsLoading(true);
    var data = {
      enterpriseID: localStorage.getItem("enterpriseID"),
      ebcID: categoryId,
      text: query
    }
    api.post('/apis/EnterpriseBookCategores/getBookWithFilter', data).then((res) => {
      if (res.data.success) {
        const resData = res.data.data;
        setArrayBook(resData)
      }
      setIsLoading(false);
    }).catch((error) => {
      console.log(error);
    });
  };

  const onCategoryClick = (data) => {
    localStorage.setItem("selectedChannelCategoryId", data.ebcID);
    setSelectedCategory(data)
    setSelectedCategoryId(data.ebcID)
    getBookByCategory(data.ebcID)
  };

  const onEditBookClick = (data) => {
    setSelectedBookId(data.id)
    setCategoryDialogueShouldOpen(true)
  };
  const onDeleteBookClick = (e) => {
    seObjDeleteConfirmation({
      deleteMessage: "Are you sure you want to remove book from folder?",
      deleteConfirmationShouldOpen: true,
      selectedDeleteId: e,
      deleteType: "2"
    })
  };

  const deleteConfirmation = (e) => {
    seObjDeleteConfirmation({
      deleteMessage: "Are you sure you want to delete?",
      deleteConfirmationShouldOpen: true,
      selectedDeleteId: e,
      deleteType: "1"
    })
  };

  const deleteRecord = () => {
    setIsLoading(true);
    api.delete('/apis/EnterpriseBookCategores/delete/' + objDeleteConfirmation.selectedDeleteId)
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar("Folder deleted", SnackbarProperties("success"));
          getData();
        } else {
          setIsLoading(false);
          enqueueSnackbar(res.data.message, SnackbarProperties("error"));
        }
        seObjDeleteConfirmation(prevState => ({
          ...prevState,
          deleteConfirmationShouldOpen: false,
          selectedDeleteId: "",
          deleteType: ""
        }))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeBook = () => {
    setIsLoading(true);
    api.delete('/apis/BookToEnterpriseCategory/delete/' + objDeleteConfirmation.selectedDeleteId + "/" + selectedCategoryId)
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar("Book removed", SnackbarProperties("success"));
          getBookByCategory(selectedCategoryId)
        } else {
          setIsLoading(false);
          enqueueSnackbar(res.data.message, SnackbarProperties("error"));
        }
        seObjDeleteConfirmation(prevState => ({
          ...prevState,
          deleteConfirmationShouldOpen: false,
          selectedDeleteId: "",
          deleteType: ""
        }))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateConfirmAlert = (value) => {
    if (value) {
      if (objDeleteConfirmation.deleteType == "1") {
        deleteRecord();
      } if (objDeleteConfirmation.deleteType == "2") {
        removeBook()
      }

    }
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  return (
    <>
      <Box sx={{ backgroundColor: 'background.default', height: '100%', py: 2 }}>
        <Container maxWidth={'xxl'} minWidth='100%' sx={{ overflow: 'hidden', height: '100%', borderRadius: 2 }}>
          <Grid container justifyContent="space-between" spacing={3} >
            <Grid item>
              <Typography color="textPrimary" variant="h5" >
                Channel Folders
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button color="primary" startIcon={<PlusIcon fontSize="small" />} sx={{ m: 1, fontSize: "12px", fontWeight: "normal" }} variant="contained"
                  onClick={() => {
                    navigate("/BookEnterpriseCategory/manage", {
                      state: { categoryId: "" }
                    });
                  }}
                >
                  Add Folder
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Card sx={{ mt: 3, overflow: 'auto', height: 'calc(100% - 60px)' }}>
            <Grid container sx={{ py: 2, px: 2 }} spacing={2} >
              <Grid item md={4} xs={4}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search..."
                  value={query}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={4} >
                <Autocomplete
                  value={selectedCategory}
                  disableClearable={true}
                  getOptionLabel={(option) => option.categoryName}
                  options={arrayData}
                  getOptionSelected={(option, value) => option.ebcID === value.ebcID}
                  onChange={(event, newValue) => {
                    setSelectedCategory(newValue)
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      name="selectedCategory"
                      variant="outlined"
                      label="Select Folder"
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item md={2} xs={2} >
                <Button color="primary" sx={{ m: 1, fontSize: "12px", fontWeight: "normal" }} variant="contained"
                  onClick={(e) => {
                    localStorage.setItem("selectedChannelCategoryId", selectedCategory.ebcID);
                    setSelectedCategoryId(selectedCategory.ebcID)
                    getBookByCategory(selectedCategory.ebcID)
                  }}
                >
                  Search
                </Button>
              </Grid>
            </Grid>

            <Stack direction="row" sx={{ height: 'calc(100% - 100px)' }} spacing={0}>
              <Box sx={{ flex: '0 0 30%', overflow: 'auto' }}>
                <BookEnterpriseCategoryTable arrayData={arrayData} selectedDataId={selectedCategoryId} onDelete={deleteConfirmation} onCategoryClick={onCategoryClick} sx={{ borderRight: '1px solid #E1E3E8' }} />
              </Box>
              <Box sx={{ flex: '0 0 70%', overflow: 'auto' }}>
                <BookListTable arrayData={arrayBook} onDelete={onDeleteBookClick} onEdit={onEditBookClick} />
              </Box>
            </Stack>

          </Card>
        </Container>
        {categoryDialogueShouldOpen && (
          <CategoryAlert
            bookID={selectedBookId}
            onClose={(e) => {
              setSelectedBookId("")
              setCategoryDialogueShouldOpen(false)
            }}
          />
        )}
        {objDeleteConfirmation.deleteConfirmationShouldOpen && (
          <ConfirmAlert
            title={"Confirmation"}
            body={objDeleteConfirmation.deleteMessage}
            updateConfirmAlert={(e, value) => {
              seObjDeleteConfirmation(prevState => ({
                ...prevState,
                deleteConfirmationShouldOpen: false
              }));
              updateConfirmAlert(value);
            }}
          />
        )}

        <Backdrop style={{ zIndex: 1 }} open={isLoading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  );
};
