import React, { useState, useEffect } from 'react';
import { Box, Button, Container, Grid, Typography, Card, InputAdornment, Backdrop, CircularProgress, TextField, Stack } from '@mui/material';
import SearchIcon from '../../icons/Search';
import api from '../../lib/axios';
import { ConfirmAlert } from '../../customControls/confirmAlert';
import SnackbarProperties from '../../components/SnackbarProperties';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import LicenceListTable from './LicenceListTable';

export const LicenceList = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [arrayData, setArrayData] = useState([]);
  const [query, setQuery] = useState("");
  const [filteredArrayData, setFilteredArrayData] = useState([]);

  const [objDeleteConfirmation, seObjDeleteConfirmation] = useState({
    deleteMessage: "Are you sure you want to delete?",
    deleteConfirmationShouldOpen: false,
    selectedDeleteId: "",
    deleteType: ""
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setIsLoading(true);
    api.get('/apis/EnterpriseLicencePackages/getAll')
      .then((res) => {
        if (res.data.success) {
          const resData = res.data.data;
          const filterArray = applyFilters(resData, query);
          setArrayData(resData);
          setFilteredArrayData(filterArray);
          setQuery("");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateConfirmAlert = (value) => {
    if (value) {
      if (objDeleteConfirmation.deleteType == "1") {

      } if (objDeleteConfirmation.deleteType == "2") {

      }
    }
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    const filterArray = applyFilters(arrayData, event.target.value);
    setFilteredArrayData(filterArray);
  };

  const applyFilters = (arrayDataAll, query) => {
    const finalArray = arrayDataAll.filter((dataObj) => {
      let matches = true;
      const properties = ['enterprise_short_name'];
      let containsQuery = false;

      properties.forEach((property) => {
        const str = dataObj[property] + "";
        if (str.toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
      return matches;
    });

    return finalArray.length > 0 ? [...finalArray] : [];
  };

  return (
    <>
      <Box sx={{ backgroundColor: 'background.default', height: '100%', py: 2 }}>
        <Container maxWidth={'xxl'} minWidth='100%' sx={{ overflow: 'hidden', height: '100%', borderRadius: 2 }}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Licences
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
              </Box>
            </Grid>
          </Grid>

          <Card sx={{ mt: 3 }}>
            <Grid container sx={{ py: 2, px: 2 }} spacing={2}>
              <Grid item md={6} xs={6} >
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search..."
                  value={query}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <LicenceListTable arrayData={filteredArrayData} />
          </Card>
        </Container>
        {objDeleteConfirmation.deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={objDeleteConfirmation.deleteMessage}
          updateConfirmAlert={(e, value) => {
            seObjDeleteConfirmation(prevState => ({
              ...prevState,
              deleteConfirmationShouldOpen: false
            }));
            updateConfirmAlert(value);
          }} />
        )}
        <Backdrop style={{ zIndex: 1 }} open={isLoading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box >
    </>
  );
}
