import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { AppBar, Box, Toolbar, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InfoIcon from '@mui/icons-material/Info';
import BookIcon from '@mui/icons-material/Book';
import PublicIcon from '@mui/icons-material/Public';
import StarIcon from '@mui/icons-material/Star';
import LicenseIcon from '@mui/icons-material/VerifiedUser';
import FolderIcon from '@mui/icons-material/Folder';
import Logo from '../../images/logo.png';
import { getRole } from '../helper';
import api from '../../lib/axios';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Styled component for the sidebar
const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme, open, isSmallScreen }) => ({
  backgroundColor: 'black',
  height: '100vh',
  width: isSmallScreen ? (open ? '250px' : '0px') : (open ? '250px' : '120px'), // Adjust width based on state and screen size
  position: 'fixed',
  top: 0,
  left: 0,
  boxShadow: open ? '6px 0px 14px rgba(0, 0, 0, 10)' : 'none',
  zIndex: theme.zIndex.drawer + 100,
  transition: 'width 0.3s ease', // Smooth transition
  overflow: 'hidden',
}));

const DashboardNavbar = ({ onSidebarToggle, sidebarOpen, isSmallScreen }) => {
  const navigate = useNavigate();
  const [sites, setSites] = React.useState([]);
  const [objPoints, setObjPoints] = React.useState(null);

  const navigationOptions = [
    { title: 'My Books', path: '/books', icon: <BookIcon />, roles: ["Admin", "SuperAdmin", "Standard", "EnterpriseAdmin"] },
    { title: 'My Public Books', path: '/bookPublic', icon: <PublicIcon />, roles: ["Admin", "SuperAdmin", "Standard", "EnterpriseAdmin"] },
    { title: 'Books to Review', path: '/bookReview', icon: <StarIcon />, roles: ["Admin", "SuperAdmin"] },
    { title: 'Licence Users', path: '/EnterpriseLicenceUser', icon: <LicenseIcon />, roles: ["Admin", "SuperAdmin", "EnterpriseAdmin"] },
    { title: 'Licences', path: '/Licence', icon: <LicenseIcon />, roles: ["Admin", "SuperAdmin"] },
    { title: 'Channels', path: '/EnterpriseList', icon: <FolderIcon />, roles: ["Admin", "SuperAdmin"] },
    { title: 'Channel Folders', path: '/BookEnterpriseCategory', icon: <FolderIcon />, roles: ["EnterpriseAdmin"] },
    { title: 'Public Folders', path: '/bookDownloadCategory', icon: <FolderIcon />, roles: ["Admin", "SuperAdmin"] },
    { title: 'My Folders', path: '/subject', icon: <FolderIcon />, roles: ["Admin", "SuperAdmin", "Standard", "EnterpriseAdmin"] },
  ];

  // Fetch sites data
  const getSites = React.useCallback(async () => {
    try {
      const res = await api.get('/apis/companysites/byCompany/');
      if (res.data.success) {
        const data = res.data.data;
        if (!localStorage.getItem("timeZone")) {
          localStorage.setItem("timeZone", data[0].timeZone);
        }
        if (!localStorage.getItem("siteID")) {
          localStorage.setItem("siteID", data[0]._id);
          window.location.href = "/";
        }
        setSites(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  // Fetch point details
  const getPointDetail = async () => {
    try {
      const res = await api.post('/apis/ImageToSpeech/getPointDetail');
      if (res.data.success) {
        const resData = res.data.data;
        localStorage.setItem("isSubscription", resData.subscriptionStatus);
        setObjPoints(resData);
      } else {
        localStorage.setItem("isSubscription", "0");
        setObjPoints(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (localStorage.getItem("role") !== "Standard") {
      getSites();
    }
    getPointDetail();
  }, [getSites]);

  const handleSidebarToggle = () => {
    onSidebarToggle(); // Trigger the toggle function passed from parent
    console.log('Sidebar Open State:', !sidebarOpen); // Should log updated state
  };

  const sidebarContent = (
    <Toolbar sx={{ display: 'flex', flexDirection: 'column', height: '100%', padding: 2, boxShadow: '4px 0px 8px rgba(0, 0, 0, 0.5)' }}>
      {/* Logo and Toggle Button */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, justifyContent: 'space-between', width:'50%' }}>
        <RouterLink to="/">
          <img src={Logo} alt="Logo" height="30" />
        </RouterLink>
        
        <IconButton
          color="inherit"
          edge="start"
          onClick={handleSidebarToggle}
          sx={{ color: 'white' }}
        >
          {sidebarOpen ? <ArrowBackIosNewIcon  /> : <ArrowForwardIosIcon />}
        </IconButton>
      </Box>

      {/* Navigation Buttons */}
      <Box sx={{ flexGrow: 1, overflowY: 'auto', width: '100%' }}>
        <List>
          {navigationOptions.map((route, index) => {
            const showRoute = route.roles.some(role => getRole(role));
            if (!showRoute) return null;

            return (
              <ListItem
                button
                key={index}
                onClick={() => {
                  localStorage.setItem("selectedPublicBookTab", "pending");
                  localStorage.setItem("selectedReviewTab", "pending");
                  navigate(route.path);
                }}
                sx={{
                  color: 'white',
                  justifyContent: sidebarOpen ? 'flex-start' : 'center',
                  paddingLeft: sidebarOpen ? '16px' : '0',
                }}
              >
                <ListItemIcon sx={{ color: 'white', minWidth: sidebarOpen ? 'unset' : '50px' }}>
                  {route.icon}
                </ListItemIcon>
                {sidebarOpen && (
                  <ListItemText primary={route.title} sx={{ whiteSpace: 'nowrap' }} />
                )}
              </ListItem>
            );
          })}
        </List>
      </Box>

      {/* AI Points Section */}
      {localStorage.getItem("isAdmin") !== "1" && objPoints && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
          <Typography variant="subtitle2" fontSize={10} color="white" whiteSpace='nowrap'>
            AI-Contingent: {objPoints.aiPointsContingent}
          </Typography>
          <Typography variant="subtitle2" fontSize={10} color="white" whiteSpace='nowrap'>
            {objPoints.remainingPercentage} % left - {objPoints.aiPointBalance}
          </Typography>
          <Typography variant="subtitle2" fontSize={10} color="white">
            AI-Extra points: {objPoints.aiPointsExtra}
          </Typography>
          <IconButton onClick={() => navigate('/PointProtokol')} size="large" sx={{ py: "0", color: "white" }}>
            <InfoIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Toolbar>
  );

  return (
    <>
      {isSmallScreen ? (
        <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleSidebarToggle}
            sx={{ position: 'fixed', top: 16, left: 16, zIndex: 1300 }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="left"
            open={sidebarOpen}
            onClose={handleSidebarToggle}
            sx={{
              '& .MuiDrawer-paper': {
                width: '250px',
                backgroundColor: 'black',
              },
            }}
          >
            {sidebarContent}
          </Drawer>
        </>
      ) : (
        <DashboardNavbarRoot open={sidebarOpen} isSmallScreen={isSmallScreen}>
          {sidebarContent}
        </DashboardNavbarRoot>
      )}
    </>
  );
};

DashboardNavbar.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  onSidebarToggle: PropTypes.func.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
};

export default DashboardNavbar;
