import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Divider, Card, Grid, TextField, Backdrop, CircularProgress, Checkbox } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import api from '../../lib/axios';
import SnackbarProperties from '../../components/SnackbarProperties';
import { useSnackbar } from 'notistack';

export const ManageUserAlert = ({ enterpriseID, obj, ...props }) => {
    const { enqueueSnackbar } = useSnackbar()
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [ysnAdmin, setYsnAdmin] = useState(0);

    useEffect(() => {
        if (obj != null && obj.email != null) {
            setEmail(obj.email)
            setYsnAdmin(obj.ysnAdmin)
        }
    }, []);

    async function apiCalling(email, ysnAdmin) {
        var data = {
            enterpriseID: enterpriseID,
            email: email,
            ysnAdmin: ysnAdmin,
        }
        if (obj != null && obj.email != null) {
            data.oldEmail = obj.email
            return await api.put('/apis/UserEnterprise/update/', data)
        } else {
            return await api.post('/apis/UserEnterprise/add/', data)
        }
    }

    return (
        <>
            <Dialog
                open
                fullWidth
                aria-labelledby="alert-dialog-title"
            >
                <Formik
                    enableReinitialize
                    initialValues={{
                        email: email,
                        ysnAdmin: ysnAdmin,
                        submit: null
                    }}
                    validationSchema={Yup
                        .object()
                        .shape({
                            email: Yup.string().max(255).required('Email is required').email('Must be a valid email'),
                        })}
                    onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                        try {
                            setEmail(values.email)
                            setIsLoading(true)
                            var data = await apiCalling(values.email, values.ysnAdmin)
                            setIsLoading(false)
                            if (data !== undefined && data.data.success) {
                                resetForm();
                                setStatus({ success: true });
                                setSubmitting(false);
                                enqueueSnackbar('Channel admin ' + (obj != null && obj.email != null ? 'updated' : 'added'), SnackbarProperties("success"));
                                if (localStorage.getItem("email") == values.email) {
                                    window.location.reload();
                                }
                                props.updateConfirmAlert(true, "");
                            } else {
                                setStatus({ success: false });
                                setErrors({ submit: data.data.message });
                                setSubmitting(false);
                                enqueueSnackbar(data.data.message, SnackbarProperties("error"));
                            }
                        } catch (err) {
                            console.error(err);
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values }) => (
                        <form
                            onSubmit={handleSubmit}
                        >
                            <Card>
                                <Box sx={{ p: 3 }}>
                                    <Grid container spacing={3} >
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                error={Boolean(touched.email && errors.email)}
                                                fullWidth
                                                helperText={touched.email && errors.email}
                                                label="Email"
                                                name="email"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.email}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <div>
                                                <Checkbox
                                                    color="primary"
                                                    onChange={(event) => {
                                                        setFieldValue("ysnAdmin", event.target.checked ? 1 : 0)
                                                    }}
                                                    checked={values.ysnAdmin}
                                                />
                                                <span>Is Admin</span>
                                            </div>
                                        </Grid>
                                        <Grid item />
                                    </Grid>
                                    <Divider sx={{ my: 2 }} />
                                    <Box sx={{ alignItems: 'center', display: 'flex' }} >
                                        <Button
                                            color="primary"
                                            variant="text"
                                            type="reset"
                                            onClick={(e) => {
                                                props.updateConfirmAlert(false, "");
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Box sx={{ flexGrow: 1 }} />
                                        <Button
                                            color="primary"
                                            sx={{ ml: 1, fontSize: "12px", fontWeight: "normal" }}
                                            disabled={isSubmitting}
                                            type="submit"
                                            variant="contained">

                                            Save
                                        </Button>

                                    </Box>
                                </Box>
                            </Card>
                        </form>
                    )}
                </Formik>
                <Backdrop style={{ zIndex: 1 }} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog >
        </>
    )
}