import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import ArrowRightIcon from '../../icons/Trash';
import PencilAltIcon from '../../icons/PencilAlt';
import Scrollbar from '../../components/Scrollbar';

const applyPagination = (users, page, limit) => users.slice(page * limit, page * limit + limit);

const BookListTable = ({ arrayData, onDelete, onEdit, ...other }) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(200);
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const paginatedCustomers = applyPagination(arrayData, page, limit);

  return (
    <Card {...other}>
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Book Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Creator
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedCustomers.map((data) => {
                return (
                  <TableRow hover key={data.id} >

                    <TableCell onClick={(e) => { }}>
                      <Typography color="textPrimary" variant="subtitle2">
                        {data.title}
                      </Typography>
                    </TableCell>

                    <TableCell onClick={(e) => { }}>
                      <Typography color="textPrimary" variant="subtitle2">
                        {data.publisher || "-"}
                      </Typography>
                    </TableCell>

                    <TableCell align="right">
                      <IconButton sx={{ paddingTop: "0", paddingBottom: "0", }} onClick={(e) => onEdit(data)} size="large">
                        <PencilAltIcon fontSize="small" />
                      </IconButton>
                      <IconButton sx={{ paddingTop: "0", paddingBottom: "0" }} onClick={(e) => { onDelete(data.id) }} size="large">
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={arrayData.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[50, 100, 200]}
      />
    </Card>
  );
};

BookListTable.propTypes = {
  arrayData: PropTypes.array.isRequired
};

export default BookListTable;
