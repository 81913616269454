import { Button, Dialog } from '@mui/material'
import React, { useEffect, useState, createRef } from 'react'
import { Box, Container, InputAdornment, TextField, Grid, Card, Typography, Backdrop, CircularProgress } from "@mui/material";
import SearchIcon from '../../icons/Search';
import api from '../../lib/axios';
import CategoryTable from './CategoryTable';
import SnackbarProperties from '../../components/SnackbarProperties';
import { useSnackbar } from 'notistack';

export const CategoryAlert = ({ bookID, onClose }) => {
    const { enqueueSnackbar } = useSnackbar()
    const [isLoading, setIsLoading] = useState(false);
    const [arrayData, setArrayData] = useState([]);
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [arraySelected, setArraySelected] = useState([]);
    const [query, setQuery] = useState("");
    const fileRef = createRef()
    const [selectedFile, setSelectedFile] = useState(null);
    const [description, setDescription] = useState("");
    const [logo, setLogo] = useState("");

    useEffect(() => {
        getCategoryList()
    }, []);

    function getCategoryList() {
        setIsLoading(true)
        api.get('/apis/EnterpriseBookCategores/byEnterprise/' + localStorage.getItem("enterpriseID")).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var filterArray = applyFilters(resData, query)
                setFilteredArrayData(filterArray)
                setArrayData(resData)
            }
            getData()
        }).catch(function (error) {
            console.log(error);
        });
    }

    function getData() {
        setIsLoading(true)
        api.get('/apis/Books/getCategoryByBook/' + bookID).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setLogo(resData.path ? resData.path : "")
                setDescription(resData.description ? resData.description : "");
                setArraySelected(resData.arrayCategoryID)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function handleQueryChange(event) {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayData, event.target.value)
        setFilteredArrayData(filterArray)
    };

    function applyFilters(arrayDataAll, query) {
        var finalArray = []
        var filteredArray = arrayDataAll
            .filter((dataObj) => {
                let matches = true;

                const properties = ['categoryName'];
                let containsQuery = false;
                properties.forEach((property) => {
                    var str = dataObj[property] + ""
                    if (str.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
        finalArray = filteredArray.length > 0 ? [...filteredArray] : []
        if (query === "") {
            return arrayDataAll
        }
        return finalArray
    }

    function onCategoryClick(id) {
        if (arraySelected.includes(id)) {
            setArraySelected(arraySelected.filter(obj => obj != id))
        } else {
            setArraySelected((prevState) => {
                const newArr = [...prevState];
                newArr.push(id);
                return newArr;
            });
        }
    }

    async function apiCalling() {
        setIsLoading(true)
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("bookID", bookID);
        const arrayJson = JSON.stringify(arraySelected);
        formData.append("arrayCategoryId", arrayJson);
        formData.append("description", description);
        // var data = {
        //     bookID: bookID,
        //     arrayCategoryId: arraySelected,
        // }
        api({
            method: "POST",
            url: '/apis/BookToEnterpriseCategory/add',
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        }).then((res) => {
            console.log(res)
            if (res.data.success) {
                onClose(true)
            } else {
                enqueueSnackbar(res.data.message, SnackbarProperties("error"));
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });

    }

    return (
        <Dialog
            open
            fullWidth
            onClose={() => (
                onClose(false)
            )}
            aria-labelledby="alert-dialog-title"

        >
            <Box sx={{ backgroundColor: 'background.default', height: '630px', py: 3 }} >
                <Container sx={{ overflow: 'auto', height: '100%', borderRadius: 2 }}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h6"
                            >
                                Channel Folders
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Box sx={{ m: -1 }}>
                                <Button
                                    color="primary"
                                    variant="text"
                                    type="reset"
                                    onClick={(e) => {
                                        onClose(false)
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    sx={{ m: 1, fontSize: "12px", fontWeight: "normal" }}
                                    variant="contained"
                                    onClick={(e) => {
                                        if (arraySelected.length > 0 && logo == "") {
                                            enqueueSnackbar("Please select image", SnackbarProperties("error"));
                                        } else if (description == "") {
                                            enqueueSnackbar("Description is required", SnackbarProperties("error"));
                                        } else {
                                            apiCalling()
                                        }

                                    }}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Card sx={{ mt: 3 }}>
                        <Grid px={2}>
                            <Grid item>
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                        display: 'flex'
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        sx={{ ml: 1, fontSize: "12px", fontWeight: "normal" }}
                                        variant="contained"
                                        onClick={(e) => {
                                            if (fileRef.current) {
                                                fileRef.current.value = null;
                                                fileRef.current.click()
                                            }
                                        }}
                                    >
                                        Upload Image
                                    </Button>
                                    {logo && (
                                        <img
                                            src={logo}
                                            alt="Logo"
                                            style={{ maxWidth: '100%', maxHeight: '100px', marginLeft: '10px' }}
                                        />
                                    )}
                                    <input
                                        type="file"
                                        name="logo"
                                        hidden
                                        ref={fileRef}
                                        accept="image/png"
                                        onChange={(e) => {
                                            const selectedFiles = e.target.files;
                                            console.log("selectedFiles", selectedFiles)
                                            const isValidFileType = Array.from(selectedFiles).every(file => {
                                                const acceptedTypes = ['image/png'];
                                                return acceptedTypes.includes(file.type);
                                            });
                                            if (!isValidFileType) {
                                                enqueueSnackbar('Upload the file in the correct format', SnackbarProperties("error"));
                                                return;
                                            }
                                            if (selectedFiles.length > 0) {
                                                setSelectedFile(selectedFiles[0])
                                                const reader = new FileReader();
                                                reader.onload = () => {
                                                    setLogo(reader.result)
                                                };
                                                reader.readAsDataURL(selectedFiles[0]);
                                            }

                                        }}
                                    />

                                </Box>
                            </Grid>
                            <Grid item md={12} xs={12} pt={2}>
                                <TextField
                                    fullWidth
                                    multiline={true}
                                    rows={4}
                                    label="Description"
                                    name="description"
                                    onChange={(e) => {
                                        setDescription(e.target.value)
                                    }}
                                    value={description}
                                    variant="outlined"
                                />

                            </Grid>
                        </Grid>
                        <Grid sx={{ py: 2, px: 2 }} >
                            <Grid item>
                                <TextField
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon fontSize="small" />
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={handleQueryChange}
                                    placeholder="Search"
                                    value={query}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <CategoryTable arrayData={filteredArrayData} arraySelected={arraySelected} onSelect={onCategoryClick} />
                    </Card>

                </Container >
            </Box >
            <Backdrop open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    )
}