import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Divider, Card, Grid, TextField, Backdrop, CircularProgress } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import api from '../../lib/axios';
import SnackbarProperties from '../../components/SnackbarProperties';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const ManageLicenceeAlert = ({ enterpriseID, ePackageID, ...props }) => {
    const { enqueueSnackbar } = useSnackbar()
    const [isLoading, setIsLoading] = useState(false);
    const [datFrom, setDateFrom] = useState(moment());
    const [datTo, setDateTo] = useState(moment().add(1, 'year'));
    const [priceNet, setPriceNet] = useState("");
    const [taxinPercent, setTaxinPercent] = useState("");
    const [totalUser, setTotalUser] = useState("");
    useEffect(() => {
        if (ePackageID != "") {
            getData()
        }
    }, []);


    function getData() {
        setIsLoading(true)
        api.get('/apis/EnterpriseLicencePackages/byId/' + ePackageID).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setDateFrom(resData.datFrom)
                setDateTo(resData.datTo)
                setPriceNet(resData.PriceNet)
                setTaxinPercent(resData.TaxinPercent)
                setTotalUser(resData.NumerOfUsers)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });

    }

    async function apiCalling(datFrom, datTo, priceNet, taxinPercent, totalUser) {
        var data = {
            enterpriseID: enterpriseID,
            datFrom: datFrom,
            datTo: datTo,
            PriceNet: priceNet,
            TaxinPercent: taxinPercent,
            NumerOfUsers: totalUser,
        }
        if (ePackageID != "") {
            return await api.put('/apis/EnterpriseLicencePackages/update/' + ePackageID, data)
        } else {
            return await api.post('/apis/EnterpriseLicencePackages/add/', data)
        }
    }

    return (
        <>
            <Dialog
                open
                fullWidth
                aria-labelledby="alert-dialog-title"
            >
                <Formik
                    enableReinitialize
                    initialValues={{
                        datFrom: datFrom,
                        datTo: datTo,
                        priceNet: priceNet,
                        taxinPercent: taxinPercent,
                        totalUser: totalUser,
                        submit: null
                    }}
                    validationSchema={Yup
                        .object()
                        .shape({
                            datFrom: Yup.date().required('From date is required'),
                            datTo: Yup.date().required('To Date is required').test('is-greater-than-fromDate', 'To date must be greater than from date', function (value) {
                                const { datFrom } = this.parent;
                                return datFrom < value;
                            }),
                            priceNet: Yup.string().required('Net price is required'),
                            taxinPercent: Yup.string().required('Text in percent is required'),
                            totalUser: Yup.string().required('Total user is required'),
                        })}
                    onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                        try {
                            setIsLoading(true)
                            var data = await apiCalling(values.datFrom, values.datTo, values.priceNet, values.taxinPercent, values.totalUser)
                            setIsLoading(false)
                            if (data !== undefined && data.data.success) {
                                resetForm();
                                setStatus({ success: true });
                                setSubmitting(false);
                                enqueueSnackbar('Licence ' + (ePackageID == "" ? "added" : "updated"), SnackbarProperties("success"));
                                props.updateConfirmAlert(true, "");
                            } else {
                                setStatus({ success: false });
                                setErrors({ submit: data.data.message });
                                setSubmitting(false);
                                enqueueSnackbar(data.data.message, SnackbarProperties("error"));
                            }
                        } catch (err) {
                            console.error(err);
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values }) => (
                        <form
                            onSubmit={handleSubmit}
                        >
                            <Card>
                                <Box sx={{ p: 3 }}>
                                    <Grid container spacing={3} >
                                        <Grid item md={12} xs={12} >
                                            <DatePicker
                                                inputFormat="dd/MM/yyyy"
                                                value={values.datFrom}
                                                onBlur={handleBlur}
                                                onChange={(newValue) => {
                                                    setFieldValue("datFrom", newValue);
                                                }}
                                                label='From Date'
                                                name="datFrom"
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    error={Boolean(touched.datFrom && errors.datFrom)}
                                                    fullWidth
                                                    helperText={touched.datFrom && errors.datFrom}
                                                    label='From Date'
                                                    name="datFrom"
                                                    onKeyDown={(e) => { if (e.key !== 'Tab') { e.preventDefault(); } }}

                                                    variant="outlined"
                                                />}
                                            />
                                        </Grid>

                                        <Grid item md={12} xs={12} >
                                            <DatePicker
                                                inputFormat="dd/MM/yyyy"
                                                value={values.datTo}
                                                onBlur={handleBlur}
                                                onChange={(newValue) => {
                                                    setFieldValue("datTo", newValue);
                                                }}
                                                label='To Date'
                                                name="datTo"
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    error={Boolean(touched.datTo && errors.datTo)}
                                                    fullWidth
                                                    helperText={touched.datTo && errors.datTo}
                                                    label='To Date'
                                                    name="datTo"
                                                    onKeyDown={(e) => { if (e.key !== 'Tab') { e.preventDefault(); } }}

                                                    variant="outlined"
                                                />}
                                            />
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                error={Boolean(touched.priceNet && errors.priceNet)}
                                                fullWidth
                                                helperText={touched.priceNet && errors.priceNet}
                                                label="Net price"
                                                type="number"
                                                name="priceNet"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.priceNet}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                error={Boolean(touched.taxinPercent && errors.taxinPercent)}
                                                fullWidth
                                                helperText={touched.taxinPercent && errors.taxinPercent}
                                                label="Tax In Percent"
                                                type="number"
                                                name="taxinPercent"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.taxinPercent}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                disabled={ePackageID != ""}
                                                error={Boolean(touched.totalUser && errors.totalUser)}
                                                fullWidth
                                                helperText={touched.totalUser && errors.totalUser}
                                                label="Total User"
                                                type="number"
                                                name="totalUser"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.totalUser}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item />
                                    </Grid>
                                    <Divider sx={{ my: 2 }} />
                                    <Box sx={{ alignItems: 'center', display: 'flex' }} >
                                        <Button
                                            color="primary"
                                            variant="text"
                                            type="reset"
                                            onClick={(e) => {
                                                props.updateConfirmAlert(false, "");
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Box sx={{ flexGrow: 1 }} />
                                        <Button
                                            color="primary"
                                            sx={{ ml: 1, fontSize: "12px", fontWeight: "normal" }}
                                            disabled={isSubmitting}
                                            type="submit"
                                            variant="contained">

                                            {ePackageID == "" ? "Add" : "Update"}
                                        </Button>

                                    </Box>
                                </Box>
                            </Card>
                        </form>
                    )}
                </Formik>
                <Backdrop style={{ zIndex: 1 }} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        </>
    )
}