import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box } from '@mui/material';
import 'react-perfect-scrollbar/dist/css/styles.css';
const Scrollbar = forwardRef((props, ref) => {
  const { children, loadOld, ...other } = props;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  if (isMobile) {
    return (
      <Box
        ref={ref}
        sx={{ overflowX: 'auto' }}
      >
        {children}
      </Box>
    );
  }
  if (loadOld) {
    return (
      <PerfectScrollbar
        // @ts-ignore
        ref={ref}
        {...other}
      >
        {children}
      </PerfectScrollbar>
    );
  } else {
    return children
  }
});

Scrollbar.propTypes = {
  children: PropTypes.node
};

export default Scrollbar;
