import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Divider, Card, Grid, TextField, Backdrop, CircularProgress, Dialog } from '@mui/material';
import SnackbarProperties from '../../components/SnackbarProperties';
import api from '../../lib/axios';
import { useSnackbar } from 'notistack';
function RefereAlert({ ...props }) {
    const { enqueueSnackbar } = useSnackbar()
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');

    useEffect(() => {
    }, []);

    const apiCalling = async (email) => {
        const data = {
            email: email,
        };
        return await api.post('/apis/RefereFriends/add', data);
    };

    const handleClose = () => {
        props.updateConfirmAlert(false);
    };

    return (
        <>
            <Dialog open fullWidth onClose={() => props.updateConfirmAlert(false)} aria-labelledby="alert-dialog-title">
                <Box sx={{ p: 3 }}>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            email: email,
                            submit: null
                        }}
                        validationSchema={Yup.object()
                            .shape({
                                email: Yup.string().max(255).required('Email is required').email('Must be a valid email'),
                            })}
                        onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                            try {
                                setEmail(values.email);
                                setIsLoading(true);
                                const data = await apiCalling(values.email);
                                console.log("data====", data)
                                setIsLoading(false);
                                if (data !== undefined && data.data.success) {
                                    resetForm();
                                    setStatus({ success: true });
                                    setSubmitting(false);
                                    enqueueSnackbar('Invitation sent successfully', SnackbarProperties("success"));
                                    props.updateConfirmAlert(true);
                                } else {
                                    setStatus({ success: false });
                                    setErrors({ submit: data.data.message });
                                    setSubmitting(false);
                                    enqueueSnackbar(data.data.message, SnackbarProperties("error"));
                                }
                            } catch (err) {
                                console.log(err);
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Card>
                                    <Box sx={{ p: 3 }}>
                                        <Grid container spacing={3}>
                                            <Grid item md={12} xs={12} >
                                                <TextField
                                                    error={Boolean(touched.email && errors.email)}
                                                    fullWidth
                                                    helperText={touched.email && errors.email}
                                                    label="Email"
                                                    name="email"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Divider sx={{ my: 2 }} />
                                        <Box sx={{ alignItems: 'center', display: 'flex' }} >
                                            <Button
                                                color="primary"
                                                variant="text"
                                                type="reset"
                                                onClick={(e) => {
                                                    handleClose();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Box sx={{ flexGrow: 1 }} />
                                            <Button
                                                color="primary"
                                                sx={{ ml: 1, fontSize: "12px", fontWeight: "normal" }}
                                                disabled={isSubmitting}
                                                type="submit"
                                                variant="contained"
                                            >
                                                Invite
                                            </Button>
                                        </Box>
                                    </Box>
                                </Card>
                            </form>
                        )}
                    </Formik>
                </Box>
                <Backdrop style={{ zIndex: 1 }} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        </>
    );
}
export default RefereAlert;
