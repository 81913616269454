import React, { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import BlankDashboardLayout from './components/dashboard/BlankDashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';

import { useNavigate, useLocation } from 'react-router-dom';
import { BookList } from './views/Book/BookList';
import { BookDownloadCategoryList } from './views/BookDownloadCategory/BookDownloadCategoryList';
import { BookEnterpriseCategoryList } from './views/BookEnterpriseCategory/BookEnterpriseCategoryList';
import { LicenceList } from './views/Licence/LicenceList';
import { EnterpriseList } from './views/Enterprise/EnterpriseList';
import { BookPublicList } from './views/BookPublic/BookPublicList';
import { PointProtokolList } from './views/PointProtokol/PointProtokolList';
import { SubscriptionList } from './views/Subscription/SubscriptionList';
import { SubscriptionManage } from './views/Subscription/SubscriptionManage';
import { PDFViewer } from './views/PDFViewer';
import { RefereList } from './views/Refere/RefereList';
function Loadable(Component) {
  const InjectedNavigate = function (props) {
    const navigate = useNavigate(props);
    const location = useLocation(props);
    return (<Suspense fallback={<LoadingScreen />}>
      <Component {...props} navigate={navigate} location={location} />
    </Suspense>)
  };
  return InjectedNavigate;
}

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const ForgotPassForm = Loadable(lazy(() => import('./components/authentication/login/ForgotPassForm')));
const ForgotResetPass = Loadable(lazy(() => import('./components/authentication/login/ForgotResetPass')));
const Success = Loadable(lazy(() => import('./views/Success/Success')));
const Profile = Loadable(lazy(() => import('./views/User/Profile')));
const CreateAccount = Loadable(lazy(() => import('./views/User/CreateAccount')));
const ResetPassword = Loadable(lazy(() => import('./views/User/ResetPassword')));

const BookManage = Loadable(lazy(() => import('./views/Book/BookManage')));
const BookAdd = Loadable(lazy(() => import('./views/Book/BookAdd')));

const PublicShareBookDetail = Loadable(lazy(() => import('./views/BookShare/PublicShareBookDetail')));
const BookReviewList = Loadable(lazy(() => import('./views/BookReview/BookReviewList')));
const BookReviewManage = Loadable(lazy(() => import('./views/BookReview/BookReviewManage')));
const BookReviewCommentList = Loadable(lazy(() => import('./views/BookReview/BookReviewCommentList')));
const UploadBook = Loadable(lazy(() => import('./views/BookReview/UploadBook')));
const EditPublicBook = Loadable(lazy(() => import('./views/BookReview/EditPublicBook')));

const SubjectList = Loadable(lazy(() => import('./views/Subject/SubjectList')));
const SubjectManage = Loadable(lazy(() => import('./views/Subject/SubjectManage')));

const MultipleAdd = Loadable(lazy(() => import('./views/Chapter/MultipleAdd')));
const ChapterList = Loadable(lazy(() => import('./views/Chapter/ChapterList')));
const ChapterManage = Loadable(lazy(() => import('./views/Chapter/ChapterManage')));
const ImageAlerts = Loadable(lazy(() => import('./views/Chapter/ImageAlerts')));

const ProofReading = Loadable(lazy(() => import('./views/Chapter/ProofReading')));

const SequenceManage = Loadable(lazy(() => import('./views/Sequence/SequenceManage')));
const SequenceDetail = Loadable(lazy(() => import('./views/Sequence/SequenceDetail')));
const ImageAnnotateAlerts = Loadable(lazy(() => import('./views/Sequence/ImageAnnotateAlerts')));
const SequenceZoomView = Loadable(lazy(() => import('./views/Sequence/SequenceZoomView')));

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// const BookDownloadCategoryList = Loadable(lazy(() => import('./views/BookDownloadCategory/BookDownloadCategoryList')));
const BookDownloadCategoryManage = Loadable(lazy(() => import('./views/BookDownloadCategory/BookDownloadCategoryManage')));

// const BookEnterpriseCategoryList = Loadable(lazy(() => import('./views/BookEnterpriseCategory/BookEnterpriseCategoryList')));
const BookEnterpriseCategoryManage = Loadable(lazy(() => import('./views/BookEnterpriseCategory/BookEnterpriseCategoryManage')));

const EnterpriseLicenceUserList = Loadable(lazy(() => import('./views/EnterpriseLicenceUser/EnterpriseLicenceUserList')));
const EnterpriseLicenceUserManage = Loadable(lazy(() => import('./views/EnterpriseLicenceUser/EnterpriseLicenceUserManage')));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <CreateAccount />
          </GuestGuard>
        )
      },
      {
        path: 'forgot-password',
        element: <ForgotPassForm />
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'reset-password',
        element: <ForgotResetPass />
      },
      {
        path: 'Success',
        element: <Success />
      }
    ]
  },
  {
    path: 'shareBook',
    element: (
      <GuestGuard>
        <BlankDashboardLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: '/shareBook/detail',
        element: <PublicShareBookDetail />
      },
    ]
  },
  {
    path: 'bookReview',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/bookReview',
        element: <BookReviewList />
      },
      {
        path: '/bookReview/commets',
        element: <BookReviewCommentList />
      },
      {
        path: '/bookReview/upload',
        element: <UploadBook />
      },
      {
        path: '/bookReview/manage',
        element: <BookReviewManage />
      },
      {
        path: '/bookReview/edit',
        element: <EditPublicBook />
      },
    ]
  },
  {
    path: 'bookPublic',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/bookPublic',
        element: <BookPublicList />
      },
    ]
  },
  {
    path: 'subject',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/subject',
        element: <SubjectList />
      },
      {
        path: '/subject/manage',
        element: <SubjectManage />
      },
    ]
  },
  {
    path: 'bookDownloadCategory',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/bookDownloadCategory',
        element: <BookDownloadCategoryList />
      },
      {
        path: '/bookDownloadCategory/manage',
        element: <BookDownloadCategoryManage />
      },
      {
        path: '/bookDownloadCategory/editPublicBook',
        element: <EditPublicBook />
      },
    ]
  },
  {
    path: 'BookEnterpriseCategory',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/BookEnterpriseCategory',
        element: <BookEnterpriseCategoryList />
      },
      {
        path: '/BookEnterpriseCategory/manage',
        element: <BookEnterpriseCategoryManage />
      },
    ]
  },
  {
    path: 'EnterpriseLicenceUser',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/EnterpriseLicenceUser',
        element: <EnterpriseLicenceUserList />
      },
      {
        path: '/EnterpriseLicenceUser/manage',
        element: <EnterpriseLicenceUserManage />
      },
    ]
  },
  {
    path: 'Licence',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/Licence',
        element: <LicenceList />
      },
    ]
  },
  {
    path: 'PointProtokol',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/PointProtokol',
        element: <PointProtokolList />
      },
    ]
  },
  {
    path: 'Subscription',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/Subscription',
        element: <SubscriptionList />
      },
      {
        path: '/Subscription/manage',
        element: <SubscriptionManage />
      },
    ]
  },
  {
    path: 'EnterpriseList',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/EnterpriseList',
        element: <EnterpriseList />
      },
    ]
  },
  {
    path: 'books',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/books',
        element: <BookList />
      },
      {
        path: '/books/manage',
        element: <BookManage />
      },

      {
        path: '/books/bookadd',
        element: <BookAdd />
      },
      {
        path: '/books/multiple',
        element: <MultipleAdd />
      },
      {
        path: '/books/chaptermanage',
        element: <ChapterManage />
      },
      {
        path: '/books/sequencemanage',
        element: <SequenceManage />
      },
      {
        path: '/books/sequence/detail',
        element: <SequenceDetail />
      },
      {
        path: '/books/proofReading',
        element: <ProofReading />
      },
      {
        path: '/books/comment',
        element: <BookReviewCommentList />
      },
    ]
  },
  {
    path: 'books',
    element: (
      <AuthGuard>
        <BlankDashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/books/chapter',
        element: <ChapterList />
      },
      {
        path: '/books/imagealerts',
        element: <ImageAlerts />
      },
      {
        path: '/books/sequence/ImageAnnotateAlerts',
        element: <ImageAnnotateAlerts />
      },
      {
        path: '/books/sequence/SequenceZoomView',
        element: <SequenceZoomView />
      },
    ]
  },
  {
    path: 'pdfViewer',
    element: (
      <AuthGuard>
        <BlankDashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/pdfViewer',
        element: <PDFViewer />
      },
    ]
  },
  {
    path: 'refer',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/refer',
        element: <RefereList />
      },
    ]
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        // element: <FloorView />
        element: <BookList />
      },
      // {
      //   path: '/createaccount',
      //   element: <CreateAccount />
      // },
      {
        path: '/profile',
        element: <Profile />
      },
      {
        path: '/resetpassword',
        element: <ResetPassword />
      }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '/',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
