import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography, Card, InputAdornment, Backdrop, CircularProgress, TextField } from '@mui/material';
import SearchIcon from '../../icons/Search';
import api from '../../lib/axios';
import PointProtokolTable from './PointProtokolTable';

export const PointProtokolList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [arrayData, setArrayData] = useState([]);
  const [query, setQuery] = useState("");
  const [filteredArrayData, setFilteredArrayData] = useState([]);

  useEffect(() => {
    getPointProtokolList();
  }, []);

  function getPointProtokolList() {
    api.get('/apis/AiPointsProtokolls/byUser').then((res) => {
      if (res.data.success) {
        const resData = res.data.data;
        const filterArray = applyFilters(resData, query);
        setArrayData(resData);
        setFilteredArrayData(filterArray);
        setQuery("");
      }
      setIsLoading(false);
    }).catch(function (error) {
      console.log(error);
    });

  }

  const handleQueryChange = (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    const filterArray = applyFilters(arrayData, newQuery);
    setFilteredArrayData(filterArray);
  };

  const applyFilters = (arrayDataAll, query) => {
    if (query === "") {
      return arrayDataAll;
    }

    return arrayDataAll.filter((dataObj) => {
      const properties = ['description', 'bookName'];

      return properties.some((property) => dataObj[property] != null && dataObj[property].toLowerCase().includes(query.toLowerCase()));
    });
  }

  return (
    <>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 5 }}>
        <Container maxWidth={'xxl'}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Protokolls
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
              </Box>
            </Grid>
          </Grid>

          <Card sx={{ mt: 3 }}>
            <Grid container sx={{ py: 2, px: 2 }} spacing={2}>
              <Grid item md={6} xs={6}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search"
                  value={query}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} xs={3}>
              </Grid>
            </Grid>
            <PointProtokolTable arrayData={filteredArrayData} />
          </Card>
        </Container>

        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  );
};
