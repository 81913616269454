import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, IconButton } from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import moment from 'moment';
import TrashIcon from '../../icons/Trash';

const applyPagination = (users, page, limit) => users.slice(page * limit, page * limit + limit);

const BookPublicReviewListTable = ({ arrayData, onDelete, ...other }) => {

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(200);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const paginatedCustomers = applyPagination(arrayData, page, limit);

  const calculateDuration = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);

    const durationMs = end - start;

    const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor((durationMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));

    let durationString = "";
    if (days > 0) {
      durationString += days + (days === 1 ? " day " : " days ");
    } else {
      durationString += "0 day "
    }
    if (hours > 0) {
      durationString += hours + (hours === 1 ? " hr " : " hrs ");
    } else {
      durationString += "0 hr "
    }
    if (minutes > 0) {
      durationString += minutes + (minutes === 1 ? " min " : " mins ");
    } else {
      durationString += "0 min "
    }
    if (durationString.trim() === "") {
      durationString = "0 days 0 hrs 0 mins";
    }
    return durationString.trim();
  }

  const onClick = (id, bookSaleReqId, objSaleReq) => {

  }

  return (
    <Card {...other}>
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table size="medium">
            <TableHead>
              <TableRow>

                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Book Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Title
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Requested Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Duration
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Creator
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedCustomers.map((data) => {
                return (
                  <TableRow hover key={data.id} >

                    <TableCell onClick={(e) => onClick(data.bookID, data.id, data)} >
                      <Typography color="textPrimary" variant="subtitle2">
                        {data.bookName}
                      </Typography>
                    </TableCell>

                    <TableCell onClick={(e) => onClick(data.bookID, data.id, data)} >
                      <Typography color="textPrimary" variant="subtitle2">
                        {data.title}
                      </Typography>
                    </TableCell>

                    <TableCell onClick={(e) => onClick(data.bookID, data.id, data)}>
                      <Typography color="textPrimary" variant="subtitle2" >
                        {data.requestedDate ? moment(data.requestedDate).format("DD/MM/YY hh:mm a") : "-"}
                      </Typography>
                    </TableCell>

                    <TableCell onClick={(e) => onClick(data.bookID, data.id, data)}>
                      <Typography color="textPrimary" variant="subtitle2">
                        {data.requestedDate ? calculateDuration(data.requestedDate, (data.approveDate ? data.approveDate : moment().format("DD/MM/YY hh:mm a"))) : "-"}
                      </Typography>
                    </TableCell>

                    <TableCell onClick={(e) => onClick(data.bookID, data.id, data)}>
                      <Typography color="textPrimary" variant="subtitle2">
                        {data.publisher ? data.publisher : "-"}
                      </Typography>
                    </TableCell>

                    <TableCell align="right" onClick={(e) => onClick(data.bookID, data.id, data)}>
                      <IconButton
                        onClick={(e) => {
                          onDelete(data.id)
                        }}
                        size="large">
                        <TrashIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={arrayData.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[50, 100, 200]}
      />
    </Card>
  );
};

BookPublicReviewListTable.propTypes = {
  arrayData: PropTypes.array.isRequired
};

export default BookPublicReviewListTable;
