import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, Divider, Card, Grid, TextField, Typography, Backdrop, CircularProgress, Autocomplete } from '@mui/material';
import api from '../../lib/axios';
import SnackbarProperties from '../../components/SnackbarProperties';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

export const SubscriptionManage = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [arrayProduct, setArrayProduct] = useState([]);
  const [email, setEmail] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [point, setPoint] = useState("");

  useEffect(() => {
    getSubscriptionProductData()
  }, [])

  function getSubscriptionProductData() {
    setIsLoading(true)
    api.get('/apis/SubscriptionProducts/getAllProducts/').then((res) => {
      if (res.data.success) {
        var resData = res.data.data
        setArrayProduct(resData)
      }
      setIsLoading(false)
    }).catch(function (error) {
      console.log(error);
    });

  }

  const apiCalling = async (email, selectedProduct, point) => {
    var data = {
      email: email,
      subscriptionProductId: selectedProduct.id,
      aIPoints: point,
    }
    return await api.post('/apis/SubscriptionDetails/add', data);
  };

  return (
    <>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
        <Container maxWidth={'xxl'}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Add Subscription
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Formik
              enableReinitialize
              initialValues={{
                email: email,
                selectedProduct: selectedProduct,
                point: point,
                submit: null
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().max(255).required('Email is required').email('Must be a valid email'),
                selectedProduct: Yup.object().nullable().required("Subscription is required"),
                point: Yup.string().required('Points is required'),
              })}
              onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                try {
                  setEmail(values.email);
                  setSelectedProduct(values.selectedProduct)
                  setPoint(values.point)
                  setIsLoading(true);
                  const data = await apiCalling(values.email, values.selectedProduct, values.point);
                  if (data !== undefined && data.data.success) {
                    resetForm();
                    setStatus({ success: true });
                    setSubmitting(false);
                    enqueueSnackbar('Subscription added', SnackbarProperties("success"));
                    navigate(-1);
                  } else {
                    setStatus({ success: false });
                    setErrors({ submit: data.data.message });
                    setSubmitting(false);
                    enqueueSnackbar(data.data.message, SnackbarProperties("error"));
                  }
                } catch (err) {
                  console.error(err);
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                } finally {
                  setIsLoading(false);
                }
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values }) => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <Box sx={{ p: 3 }}>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            label="Email"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Autocomplete
                            value={values.selectedProduct}
                            getOptionLabel={(option) => option.productName}
                            options={arrayProduct}
                            getOptionSelected={(option, value) => option.id == value.id}
                            onChange={(event, newValue) => {
                              values.selectedProduct = newValue
                              if (newValue == null) {
                                setFieldValue("point", "")
                              } else {
                                setFieldValue("point", newValue.aIPoints)
                              }
                            }}
                            onBlur={handleBlur}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                error={Boolean(touched.selectedProduct && errors.selectedProduct)}
                                helperText={touched.selectedProduct && errors.selectedProduct}
                                name="selectedProduct"
                                variant="outlined"
                                label="Select Subscription"
                                {...params}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item md={6} xs={6}>
                          <TextField
                            error={Boolean(touched.point && errors.point)}
                            fullWidth
                            helperText={touched.point && errors.point}
                            label="Points"
                            type="number"
                            name="point"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.point}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item />
                      </Grid>
                      <Divider sx={{ my: 2 }} />
                      <Box sx={{ alignItems: 'center', display: 'flex' }}>
                        <Button
                          color="primary"
                          variant="text"
                          type="reset"
                          onClick={() => {
                            navigate(-1);
                          }}
                        >
                          Cancel
                        </Button>
                        <Box sx={{ flexGrow: 1 }} />
                        <Button
                          color="primary"
                          sx={{ ml: 1, fontSize: "12px", fontWeight: "normal" }}
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                        >
                          Add Subscription
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                </form>
              )}
            </Formik>
            <Backdrop style={{ zIndex: 1 }} open={isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        </Container>
      </Box>
    </>
  );
};
