import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import ArrowRightIcon from '../../icons/Trash';
import PencilAltIcon from '../../icons/PencilAlt';
import Scrollbar from '../../components/Scrollbar';
import { useNavigate } from 'react-router-dom';

const applyPagination = (users, page, limit) => users.slice(page * limit, page * limit + limit);

const BookListTable = ({ arrayData, onDelete, onEdit, ...other }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(200);
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const paginatedCustomers = applyPagination(arrayData, page, limit);

  const secondsToTime = (miliSeconds) => {
    if (miliSeconds != null && miliSeconds != "") {
      const seconds = miliSeconds / 1000;
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const roundedSeconds = Math.floor(seconds % 60); // Round seconds down

      if (hours === 0) {
        return `${String(minutes).padStart(2, '0')}:${String(roundedSeconds).padStart(2, '0')}`;
      } else {
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(roundedSeconds).padStart(2, '0')}`;
      }
    } else {
      return '00.00'
    }
  }

  const onClick = (data) => {
    navigate("/books/chapter",
      {
        state: { bookID: data.id }
      });
  }

  return (
    <Card {...other}>
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Book Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Intern Code
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Duration
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedCustomers.map((data) => {

                return (
                  <TableRow hover key={data.id}>

                    <TableCell onClick={(e) => onClick(data)}>
                      <Typography color="textPrimary" variant="subtitle2">
                        {data.bookName}
                      </Typography>
                    </TableCell>

                    <TableCell onClick={(e) => onClick(data)}>
                      <Typography color="textPrimary" variant="subtitle2" >
                        {data.internCode != null && data.internCode != "" ? data.internCode : "-"}
                      </Typography>
                    </TableCell>

                    <TableCell onClick={(e) => onClick(data)}>
                      <Typography color="textPrimary" variant="subtitle2" >
                        {secondsToTime(data.duration)}
                      </Typography>
                    </TableCell>

                    <TableCell align="right">
                      <IconButton sx={{ paddingTop: "0", paddingBottom: "0", }} onClick={(e) => onEdit(data)} size="large">
                        <PencilAltIcon fontSize="small" />
                      </IconButton>
                      <IconButton sx={{ paddingTop: "0", paddingBottom: "0" }} onClick={(e) => { onDelete(data.id) }} size="large">
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={arrayData.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[50, 100, 200]}
      />
    </Card>
  );
};

BookListTable.propTypes = {
  arrayData: PropTypes.array.isRequired
};

export default BookListTable;
