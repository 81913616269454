import { styled, TextField } from '@mui/material';

const CustomTextField = styled(TextField)({
 
  "& .MuiInputBase-root": {
    borderRadius: "10px",
    backgroundColor: "#F6F7F7",
    color: "gray",
    height: "60px",
    padding:"8px",
    boxSizing: "border-box", 
    boxShadow: "inset 1px 1px 0px rgba(0, 0, 0, 0.1)",
    
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #f0f0f0",
      boxShadow: "inset 2px 0px 2px rgba(0, 0, 0, 0.1)",
    },
  },
  "& .MuiInputLabel-root": {
    color: "gray",
    position: "absolute", // Position label absolutely
    left: "20px", // Adjust left position
    top: "45%", // Center vertically
    transform: "translateY(-50%)", // Center vertically
    pointerEvents: "none", // Ensure label doesn’t intercept click events
    "&.MuiInputLabel-shrink": {
      top: "8px", // Position label when shrunk
      left: "20px",
      transform: "translateY(0) scale(0.75)", // Adjust scaling and positioning when shrunk
    },
  },
  "& .MuiFormControl-root": {
    position: "relative", // Position relative to ensure the label is within the container
  },
});

export default CustomTextField;
