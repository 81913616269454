import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, Container, Grid, Typography, Card, InputAdornment, Backdrop, CircularProgress, TextField, Stack, Autocomplete } from '@mui/material';
import SearchIcon from '../../icons/Search';
import PlusIcon from '../../icons/Plus';
import api from '../../lib/axios';
import { ConfirmAlert } from '../../customControls/confirmAlert';
import BookListTable from './BookListTable';
import SnackbarProperties from '../../components/SnackbarProperties';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import SubjectTable from './SubjectTable';

export const BookList = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [arraySubject, setArraySubject] = useState([]);
  const [arrayData, setArrayData] = useState([]);
  const [selectedSubjectId, setSelectedSubjectId] = useState("0");
  const [selectedSubject, setSelectedSubject] = useState({ id: "0", name: "All" });
  const [query, setQuery] = useState("");
  const fileRef = useRef(null);
  const [objDeleteConfirmation, seObjDeleteConfirmation] = useState({
    deleteMessage: "Are you sure you want to delete?",
    deleteConfirmationShouldOpen: false,
    selectedDeleteId: "",
    deleteType: ""
  });

  useEffect(() => {
    localStorage.setItem("selectedSequenceDetailId", "");
    getSubjectData()
  }, []);

  function getSubjectData() {
    setIsLoading(true);
    api.get('/apis/Subjects/allSubjects').then((res) => {
      if (res.data.success) {
        var resData = res.data.data
        var array = [{ id: "0", name: "All" }, { id: "-1", name: "No Folder" }]
        var arraySubject = [...array, ...resData]
        setArraySubject(arraySubject)
        var subjectId = localStorage.getItem("selectedSubjectId");
        if (subjectId == null || subjectId == "") {
          subjectId = "0"
        }
        setSelectedSubjectId(subjectId)
        var selectedSubject = arraySubject.find(obj => obj.id == subjectId)
        setSelectedSubject(selectedSubject)
        getBookData(subjectId)
      }
      setIsLoading(false);
    }).catch(function (error) {
      console.log(error);
    });
  }

  const getBookData = (subjectId) => {
    setIsLoading(true);
    var data = {
      text: query,
      subjectId: subjectId,
    }
    api.post('/apis/books/filter', data).then((res) => {
      if (res.data.success) {
        const resData = res.data.data;
        setArrayData(res.data.data);
      }
      setIsLoading(false);
    }).catch(function (error) {
      console.log(error);
    });
  };

  const getBookCount = () => {
    setIsLoading(true);
    api.get('/apis/books/checkCount').then((res) => {
      if (res.data.success) {
        const resData = res.data.data;
        if (resData.count == 0) {
          navigate("/books/manage");
        } else {
          enqueueSnackbar("Please buy subscription from mobile application", SnackbarProperties('error'));
        }
      }
      setIsLoading(false);
    }).catch(function (error) {
      console.log(error);
    });
  };

  const uploadFiles = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    setIsLoading(true);
    api({
      method: "POST",
      url: '/apis/Books/import',
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((res) => {
      if (res.data.success) {
        enqueueSnackbar("Book imported successfully", SnackbarProperties('success'));
        setIsLoading(false);
        navigate("/books/chapter", { state: { bookID: res.data.data.id } });
      } else {
        enqueueSnackbar(res.data.message, SnackbarProperties('error'));
        setIsLoading(false);
      }
    }).catch(function (error) {
      console.log(error);
    });
  };

  const onSubjectClick = (data) => {
    localStorage.setItem("selectedSubjectId", data.id);
    setSelectedSubject(data)
    setSelectedSubjectId(data.id)
    getBookData(data.id)
  };
  const deleteConfirmation = (e) => {
    seObjDeleteConfirmation({
      deleteMessage: "Are you sure you want to delete?",
      deleteConfirmationShouldOpen: true,
      selectedDeleteId: e,
      deleteType: "1"
    })
  };
  const onDeleteSubject = (e) => {
    seObjDeleteConfirmation({
      deleteMessage: "Are you sure you want to delete?",
      deleteConfirmationShouldOpen: true,
      selectedDeleteId: e,
      deleteType: "2"
    })
  };

  const deleteSubject = () => {
    setIsLoading(true);
    api.delete('/apis/Subjects/delete/' + objDeleteConfirmation.selectedDeleteId).then((res) => {
      if (res.data.success) {
        getSubjectData();
        enqueueSnackbar("Folder Deleted", SnackbarProperties("success"));
      } else {
        setIsLoading(false);
        enqueueSnackbar(res.data.message, SnackbarProperties("error"));
      }
      seObjDeleteConfirmation(prevState => ({
        ...prevState,
        deleteConfirmationShouldOpen: false,
        selectedDeleteId: "",
        deleteType: ""
      }))
    }).catch(function (error) {
      console.log(error);
    });
  };

  const deleteRecord = () => {
    setIsLoading(true);
    api.delete('/apis/Books/delete/' + objDeleteConfirmation.selectedDeleteId).then((res) => {
      if (res.data.success) {
        getBookData(selectedSubjectId);
        enqueueSnackbar("Book Deleted", SnackbarProperties("success"));
      } else {
        setIsLoading(false);
        enqueueSnackbar(res.data.message, SnackbarProperties("error"));
      }
      seObjDeleteConfirmation(prevState => ({
        ...prevState,
        deleteConfirmationShouldOpen: false,
        selectedDeleteId: "",
        deleteType: ""
      }))
    }).catch(function (error) {
      console.log(error);
    });
  };

  const updateConfirmAlert = (value) => {
    if (value) {
      if (objDeleteConfirmation.deleteType == "1") {
        deleteRecord();
      } if (objDeleteConfirmation.deleteType == "2") {
        deleteSubject()
      }

    }
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  return (
    <>
      <Box sx={{ backgroundColor: 'background.default', height: '100%', py: 2 }}>
        <Container maxWidth={'xxl'} minWidth='100%' sx={{ overflow: 'hidden', height: '100%', borderRadius: 2 }}>
          <Grid container justifyContent="space-between" spacing={3} >
            <Grid item>
              <Typography color="textPrimary" variant="h5" >
                Book Manager {localStorage.getItem("enterprise") != null && localStorage.getItem("enterprise") != "" ? (" - " + localStorage.getItem("enterprise")) : ""}
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <input
                  type="file"
                  name="files"
                  hidden
                  ref={fileRef}
                  accept=".map"
                  onChange={(e) => {
                    const selectedFiles = e.target.files;
                    if (!selectedFiles || selectedFiles.length === 0) {
                      return;
                    }
                    const isValidFileType = Array.from(selectedFiles).every(file => {
                      const fileExtension = file.name.split('.').pop();
                      const acceptedExtensions = ['map'];
                      return acceptedExtensions.includes(fileExtension.toLowerCase());
                    });
                    if (!isValidFileType) {
                      enqueueSnackbar('Upload the file in the correct format', SnackbarProperties('error'));
                      return;
                    }
                    if (selectedFiles.length > 0) {
                      uploadFiles(selectedFiles[0]);
                    }
                  }}
                />
                <Button
                  color="primary"
                  sx={{ m: 1, fontSize: "12px", fontWeight: "normal" }}
                  variant="contained"
                  onClick={async (e) => {
                    if (localStorage.getItem("isSubscription") == "1") {
                      if (fileRef.current) {
                        fileRef.current.value = null;
                        fileRef.current.click()
                      }
                    } else {
                      enqueueSnackbar("You don't have any subscription!", SnackbarProperties("error"));
                    }
                  }}
                >
                  Import
                </Button>
                <Button
                  color="primary"
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1, fontSize: "12px", fontWeight: "normal" }}
                  variant="contained"
                  onClick={(e) => {
                    if (localStorage.getItem("isSubscription") == "1") {
                      navigate("/books/manage");
                    } else {
                      getBookCount()
                    }
                  }}
                >
                  Add Book
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Card sx={{ mt: 3, overflow: 'auto', height: 'calc(100% - 60px)' }}>
            <Grid container sx={{ py: 2, px: 2 }} spacing={2} >
              <Grid item md={4} xs={4} >
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search Book"
                  value={query}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={4} >
                <Autocomplete
                  value={selectedSubject}
                  disableClearable={true}
                  getOptionLabel={(option) => option.name}
                  options={arraySubject}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={(event, newValue) => {
                    setSelectedSubject(newValue)
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      name="selectedSubject"
                      variant="outlined"
                      label="Select Folder"
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item md={2} xs={2} >
                <Button color="primary" sx={{ m: 1, fontSize: "12px", fontWeight: "normal" }} variant="contained"
                  onClick={(e) => {
                    localStorage.setItem("selectedSubjectId", selectedSubject.id);
                    setSelectedSubjectId(selectedSubject.id)
                    getBookData(selectedSubject.id)
                  }}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
            <Stack direction="row" sx={{ height: 'calc(100% - 100px)' }} spacing={0}>
              <Box sx={{ flex: '0 0 0%', overflow: 'auto' }}>
                <SubjectTable arrayData={arraySubject} selectedDataId={selectedSubjectId} onDelete={onDeleteSubject} onSubjectClick={onSubjectClick} sx={{ borderRight: '1px solid #E1E3E8' }} />
              </Box>
              <Box sx={{ flex: '0 0 100%', overflow: 'auto' }}>
                <BookListTable arrayData={arrayData} onDelete={deleteConfirmation} />
              </Box>
            </Stack>

          </Card>
        </Container>
        {objDeleteConfirmation.deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
          updateConfirmAlert={(e, value) => {
            seObjDeleteConfirmation(prevState => ({
              ...prevState,
              deleteConfirmationShouldOpen: false
            }));
            updateConfirmAlert(value);
          }} />)}

        <Backdrop style={{ zIndex: 1 }} open={isLoading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box >
    </>
  );
}
