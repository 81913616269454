import React, { useState, useEffect } from 'react';
import { Box, Button, Container, Grid, Typography, Card, InputAdornment, Backdrop, CircularProgress, TextField } from '@mui/material';
import SearchIcon from '../../icons/Search';
import PlusIcon from '../../icons/Plus';
import api from '../../lib/axios';
import RefereTable from './RefereTable';
import RefereAlert from './RefereAlert';

export const RefereList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [arrayData, setArrayData] = useState([]);
  const [query, setQuery] = useState("");
  const [filteredArrayData, setFilteredArrayData] = useState([]);
  const [isOpenReferFriendDialgue, setIsOpenReferFriendDialgue] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await api.get('/apis/RefereFriends/byUser');
      if (res.data.success) {
        const resData = res.data.data;
        const filterArray = applyFilters(resData, query);
        setArrayData(resData);
        setFilteredArrayData(filterArray);
        setQuery("");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    const filterArray = applyFilters(arrayData, event.target.value);
    setFilteredArrayData(filterArray);
  };

  const applyFilters = (arrayDataAll, query) => {
    const finalArray = arrayDataAll.filter((dataObj) => {
      const properties = ['email'];
      const containsQuery = properties.some((property) => {
        const str = dataObj[property] + "";
        return str.toLowerCase().includes(query.toLowerCase());
      });
      return containsQuery;
    });
    return query === "" ? arrayDataAll : finalArray;
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 5
        }}
      >
        <Container maxWidth={'xxl'}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Refer a Friend
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1, fontSize: "12px", fontWeight: "normal" }}
                  variant="contained"
                  onClick={() => {
                    setIsOpenReferFriendDialgue(true)
                  }}
                >
                  Refer a Friend
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Card sx={{ mt: 3 }}>
            <Grid
              container
              sx={{
                py: 2,
                px: 2
              }}
              spacing={2}
            >
              <Grid
                item
                md={6}
                xs={6}
              >
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search..."
                  value={query}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <RefereTable arrayData={filteredArrayData} />
          </Card>
        </Container>
        {isOpenReferFriendDialgue && (
          <RefereAlert
            updateConfirmAlert={(e, value) => {
              if (e) {
                setIsOpenReferFriendDialgue(false)
                getData();
              } else {
                setIsOpenReferFriendDialgue(false)
              }
            }}
          />
        )}
        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  );
};
