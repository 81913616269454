import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, IconButton, Table, TableBody, TableCell, TablePagination, TableRow, Typography } from '@mui/material';
import ArrowRightIcon from '../../icons/Trash';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PencilAltIcon from '../../icons/PencilAlt';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Scrollbar from '../../components/Scrollbar';
import { useNavigate } from 'react-router-dom';

const applyPagination = (users, page, limit) => users.slice(page * limit, page * limit + limit);

const EnterpriseListTable = ({ arrayData, selectedDataId, onDelete, onEdit, onEnterpriseClick, onBookList, onAddLicence, onAddUser, ...other }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(200);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const paginatedCustomers = applyPagination(arrayData, page, limit);

  const onClick = (data) => {

  }

  return (
    <Card {...other}>
      <Scrollbar>
        <Box sx={{ minWidth: 100 }}>
          <Table size="medium">
            <TableBody>
              {paginatedCustomers.map((data) => {
                return (
                  <TableRow hover key={data.enterpriseID} style={{ backgroundColor: selectedDataId == data.enterpriseID ? "#004484" : "white" }}>
                    <TableCell onClick={(e) => onEnterpriseClick(data)} sx={{ m: 0, p: 1.2 }}>
                      <Typography variant="subtitle2" color={selectedDataId == data.enterpriseID ? "white" : "textPrimary"}>
                        {data.enterprise_short_name}
                      </Typography>
                    </TableCell>

                    <TableCell align="right" sx={{ m: 0, p: 1.2 }}>
                      <IconButton sx={{ py: "0", px: "8px", margin: "0" }} onClick={(e) => onBookList(data)} size="large" style={{ color: selectedDataId == data.enterpriseID ? "white" : 'grey' }}>
                        <FormatListBulletedIcon fontSize="small" />
                      </IconButton>
                      <IconButton sx={{ py: "0", px: "8px", margin: "0" }} onClick={(e) => onAddUser(data.enterpriseID)} size="large" style={{ color: selectedDataId == data.enterpriseID ? "white" : 'grey' }}>
                        <PersonAddIcon fontSize="small" />
                      </IconButton>
                      <IconButton sx={{ py: "0", px: "8px", margin: "0" }} onClick={(e) => onAddLicence(data.enterpriseID)} size="large" style={{ color: selectedDataId == data.enterpriseID ? "white" : 'grey' }}>
                        <PostAddIcon fontSize="small" />
                      </IconButton>
                      <IconButton sx={{ py: "0", px: "8px", margin: "0" }} onClick={(e) => onEdit(data)} size="large" style={{ color: selectedDataId == data.enterpriseID ? "white" : 'grey' }}>
                        <PencilAltIcon fontSize="small" />
                      </IconButton>
                      <IconButton sx={{ py: "0", px: "8px", margin: "0" }} onClick={(e) => { onDelete(data.enterpriseID) }} size="large" style={{ color: selectedDataId == data.enterpriseID ? "white" : 'grey' }}>
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={arrayData.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[50, 100, 200]}
      />
    </Card>
  );
};

EnterpriseListTable.propTypes = {
  arrayData: PropTypes.array.isRequired
};

export default EnterpriseListTable;
