import React, { useState, useRef, createRef, useEffect } from 'react';
import { Box, Button, Container, Grid, Typography, Card, Backdrop, CircularProgress, Stack, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Dialog, TextField, Checkbox } from '@mui/material';
import SnackbarProperties from '../../components/SnackbarProperties';
import { useSnackbar } from 'notistack';
import api from '../../lib/axios';
import { useNavigate } from 'react-router';
import ArrowRightIcon from '../../icons/Trash';
import PencilAltIcon from '../../icons/PencilAlt';
import Scrollbar from '../../components/Scrollbar';
import './style.css'

const applyPagination = (users, page, limit) => users.slice(page * limit, page * limit + limit);

export const PDFViewer = ({ ...other }) => {
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate();
    const fileRef = createRef();
    const [isLoading, setIsLoading] = useState(false);
    const [documentSelectedID, setDocumentSelectedID] = useState("");
    const [arrayData, setArrayData] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(200);
    const [open, setOpen] = useState(false);
    const [command, setCommand] = useState("");
    const [result, setResult] = useState("");
    const [ysnShowEmbeddedData, setYsnShowEmbeddedData] = useState(true);
    const [documentURL, setDocumentURL] = useState("");
    const [embeddingData, setEmbeddingData] = useState("");

    const resultContainerRef = useRef(null);

    useEffect(() => {
        if (resultContainerRef.current) {
            resultContainerRef.current.scrollTop = resultContainerRef.current.scrollHeight;
        }
    }, [result]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (event) => {
        setPage(0);
        setLimit(parseInt(event.target.value, 10));
    };

    const paginatedCustomers = applyPagination(arrayData, page, limit);

    async function uploadFiles(file) {
        setIsLoading(true)
        const formData = new FormData();
        formData.append("file", file);
        api.post('apis/Documents/uploadPdf/', formData).then((res) => {
            var data = res.data.data
            if (res.data.success) {
                enqueueSnackbar("File Uploaded", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                setIsLoading(false)
                getDocumentDetail(data.id)
            } else {
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    };

    const getDocumentList = () => {
        setIsLoading(true);
        api.get('/apis/Documents/all').then((res) => {
            if (res.data.success) {
                const resData = res.data.data;
                setArrayData(resData);
                setOpen(true);
            }
            setIsLoading(false);
        }).catch(function (error) {
            console.log(error);
        });
    };

    const onClick = (data) => {
        setDocumentSelectedID(data.id)
        getDocumentDetail(data.id)
        setOpen(false);
    }

    const getDocumentDetail = (selectedDocumentID) => {
        setIsLoading(true);
        api.get('/apis/Documents/byId/' + selectedDocumentID).then((res) => {
            if (res.data.success) {
                const resData = res.data.data;
                console.log("URL", resData.url);
                setDocumentURL(resData.path)
                //setArrayData(resData);
            }
            getDocumentSearchHistory(selectedDocumentID)
            //setIsLoading(false);
        }).catch(function (error) {
            console.log(error);
        });
    };

    const getDocumentSearchHistory = (selectedDocumentID) => {
        //setIsLoading(true);
        api.get('/apis/Documents/searchHistory/' + selectedDocumentID).then((res) => {
            if (res.data.success) {
                const resData = res.data.data;

                var resultData = ""
                resData.map(obj => {
                    resultData += `
                    <div class="user"><strong>User:</strong> ${obj.command}</div>
                    <div class="system"><strong>System:</strong> ${obj.result}</div>
                `;
                })
                setResult(resultData)
            }
            setIsLoading(false);
        }).catch(function (error) {
            console.log(error);
        });
    };

    const getSearchData = () => {
        var data = {
            command: command,
            documentID: documentSelectedID
        }
        api.post('/apis/Documents/searchData', data).then((res) => {
            if (res.data.success) {
                const resData = res.data.data;
                console.log("resData", resData)
                const formattedData = JSON.stringify(resData, null, 2);
                setEmbeddingData(formattedData);
            } else {
                setEmbeddingData("")
            }

        }).catch(function (error) {
            console.log(error);
        });
    };

    async function sendCommand() {
        if (ysnShowEmbeddedData) {
            getSearchData()
        }

        var resultData = result
        resultData += `<div class="user"><strong>User:</strong> ${command}</div>`
        setResult(resultData)
        setCommand("")
        const token = localStorage.getItem('accessToken')
        const response = await fetch('/apis/Documents/search/', {
            body: JSON.stringify({
                command: command,
                documentID: documentSelectedID
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        const reader = response.body
            .pipeThrough(new TextDecoderStream())
            .getReader()
        resultData += `<div class="system"><strong>System:</strong>`
        while (true) {
            const { value, done } = await reader.read()

            if (done) {
                resultData += `</div>`;
                setResult(resultData);
                break
            }
            resultData += value;
            setResult(resultData)
        }
    }

    const onSendCommandClick = () => {
        if (documentSelectedID == "") {
            enqueueSnackbar("Please select document", SnackbarProperties("error"));
        } else if (command === "") {
            enqueueSnackbar("Please enter command", SnackbarProperties("error"));
        } else {
            sendCommand();
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onSendCommandClick()
        }
    };

    return (
        <Box sx={{ backgroundColor: 'background.default', height: '100%', py: 2 }}>
            <Container maxWidth={'xxl'} sx={{ overflow: 'hidden', height: '100%', borderRadius: 2 }}>
                <Grid container justifyContent="space-between" spacing={3}>
                    <Grid item>
                        <Box sx={{ m: -1 }}>
                            <input
                                type="file"
                                name="files"
                                hidden
                                ref={fileRef}
                                accept=".pdf"
                                onChange={(e) => {
                                    const selectedFiles = e.target.files;
                                    const isValidFileType = Array.from(selectedFiles).every((file) => {
                                        return file.type === 'application/pdf';
                                    });
                                    if (!isValidFileType) {
                                        enqueueSnackbar('Upload the file in the correct format (PDF)', {
                                            anchorOrigin: {
                                                horizontal: 'right',
                                                vertical: 'top',
                                            },
                                            variant: 'error',
                                        });
                                        return;
                                    }
                                    if (selectedFiles.length > 0) {
                                        uploadFiles(selectedFiles[0]);
                                    }
                                }}
                            />
                            <Button
                                color="primary"
                                sx={{ m: 1, fontSize: "12px", fontWeight: "normal" }}
                                variant="contained"
                                onClick={async (e) => {
                                    if (fileRef.current) {
                                        fileRef.current.value = null;
                                        fileRef.current.click();
                                    }
                                }}
                            >
                                Upload Pdf
                            </Button>

                            <Button
                                color="primary"
                                sx={{ m: 1, fontSize: "12px", fontWeight: "normal" }}
                                variant="contained"
                                onClick={(e) => {
                                    getDocumentList()

                                }}
                            >
                                Select Pdf
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Stack direction="row" gap={1} alignItems="center" >
                            <Checkbox
                                color="primary"
                                onChange={(event) => {
                                    setEmbeddingData("")
                                    setYsnShowEmbeddedData(event.target.checked)
                                }}
                                checked={ysnShowEmbeddedData}
                            />
                            <Typography>Show embedded data</Typography>
                        </Stack>
                    </Grid>
                </Grid>
                {open && (
                    <Dialog open fullWidth>
                        <Card sx={{ mt: 3, overflow: 'auto', height: 'calc(100% - 60px)' }}>
                            <Stack direction="row" sx={{ height: 'calc(100% - 100px)' }} spacing={0}>
                                <Box sx={{ flex: '0 0 100%', overflow: 'auto' }}>
                                    <Card>
                                        <Scrollbar>
                                            <Box sx={{ minWidth: 700 }}>
                                                <Table size="medium">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                                                                    Text
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {paginatedCustomers.map((data) => (
                                                            <TableRow hover key={data.id}>
                                                                <TableCell onClick={(e) => onClick(data)}>
                                                                    <Typography color="textPrimary" variant="subtitle2">
                                                                        {data.name ? data.name : "-"}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {(localStorage.getItem("userID") == data.userId + "" || (data.ysnEdit == 1)) &&
                                                                        <IconButton onClick={(e) => { navigate("/books/manage", { state: { bookID: data.id } }); }} size="large" sx={{ py: "0", margin: "0" }}>
                                                                            <PencilAltIcon fontSize="small" />
                                                                        </IconButton>}
                                                                    {(localStorage.getItem("userID") == data.userId + "" || (data.ysnDeletePossible == 1)) &&
                                                                        <IconButton size="large" sx={{ py: "0", margin: "0" }}>
                                                                            <ArrowRightIcon fontSize="small" />
                                                                        </IconButton>}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Scrollbar>
                                        <TablePagination
                                            component="div"
                                            count={paginatedCustomers.length}
                                            onPageChange={handlePageChange}
                                            onRowsPerPageChange={handleLimitChange}
                                            page={page}
                                            rowsPerPage={limit}
                                            rowsPerPageOptions={[50, 100, 200]}
                                        />
                                        <Stack justifyContent={'space-between'} direction={'row'}>
                                            <Box />
                                            <Button
                                                color="primary"
                                                sx={{ m: 1, fontSize: "12px", fontWeight: "normal" }}
                                                variant="contained"
                                                onClick={(e) => {
                                                    setOpen(false);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Stack>
                                    </Card>
                                </Box>
                            </Stack>
                        </Card>
                    </Dialog>
                )}
                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={2} sx={{ height: '100%' }}>
                    <Box width={'60%'} borderColor={"#808080"} height={'100%'}>
                        <object data={documentURL} type="application/pdf" width='100%' height='100%' onClick={(e) => { }} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 113px)', width: '40%' }} height={'100%'}>
                        <div
                            ref={resultContainerRef}
                            contentEditable={false}
                            className="result-container"
                            dangerouslySetInnerHTML={{ __html: result }}
                            onInput={(e) => {
                                setResult(e.target.innerHTML)
                            }}
                            style={{ flexGrow: 1 }}
                        />
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1} marginTop={1}>
                            <TextField
                                fullWidth
                                label="command"
                                name="Command"
                                onChange={(e) => {
                                    setCommand(e.target.value)
                                }}
                                value={command}
                                variant="outlined"
                                InputProps={{
                                    style: {
                                        fontSize: '15px',
                                        color: '#262B40',
                                    }
                                }}
                                onKeyPress={handleKeyPress}
                            />
                            <Button
                                color="primary"
                                sx={{ height: 56, fontSize: "14px", fontWeight: "normal", minWidth: "130px" }}
                                variant="contained"
                                onClick={(e) => {
                                    onSendCommandClick()
                                }}
                            >
                                Send Command
                            </Button>
                        </Stack>
                        {ysnShowEmbeddedData && <Box
                            sx={{
                                marginTop: 1,
                                flexGrow: 1,
                                border: '1px solid #ccc',
                                padding: 2,
                                // maxHeight: 250,
                                minHeight: '40%',
                                overflow: 'auto'
                            }}
                        >
                            <Typography component="pre" style={{ whiteSpace: 'pre-wrap' }}>{embeddingData}</Typography>
                        </Box>}
                    </Box>
                </Stack>
                <Backdrop style={{ zIndex: 1 }} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        </Box>
    );
};
