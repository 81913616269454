import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import ArrowRightIcon from '../../icons/Trash';
import PencilAltIcon from '../../icons/PencilAlt';
import Scrollbar from '../../components/Scrollbar';
import { useNavigate } from 'react-router-dom';


const applyPagination = (users, page, limit) => users.slice(page * limit, page * limit + limit);

const SubjectTable = ({ arrayData, selectedDataId, onDelete, onSubjectClick, ...other }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(200);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };


  const onClick = (data) => {
    navigate("/subject/manage", { state: { subjectID: data.id } });
  }

  const paginatedCustomers = applyPagination(arrayData, page, limit);

  return (
    <Card {...other}>
      <Scrollbar>
        <Box sx={{ minWidth: 100 }}>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Folder Name
                  </Typography>
                </TableCell>

                <TableCell align="right">
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedCustomers.map((data) => {
                return (
                  <TableRow hover key={data.id} style={{ backgroundColor: selectedDataId == data.id ? "#004484" : "white" }}>
                    <TableCell onClick={(e) => onSubjectClick(data)}>
                      <Typography variant="subtitle2" color={selectedDataId == data.id ? "white" : "textPrimary"}>
                        {data.name}
                      </Typography>
                    </TableCell>

                    <TableCell align="right">
                      {data.id != 0 && data.id != -1 && <IconButton onClick={(e) => onClick(data)} size="large" sx={{ py: "0", margin: "0" }} style={{ color: selectedDataId == data.id ? "white" : 'grey' }}>
                        <PencilAltIcon fontSize="small" />
                      </IconButton>}

                      {data.id != 0 && data.id != -1 && <IconButton onClick={(e) => { onDelete(data.id) }} size="large" sx={{ py: "0", margin: "0" }} style={{ color: selectedDataId == data.id ? "white" : 'grey' }}>
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={paginatedCustomers.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[50, 100, 200]}
      />
    </Card>
  );
};

SubjectTable.propTypes = {
  arrayData: PropTypes.array.isRequired
};

export default SubjectTable;
