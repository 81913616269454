import React, { useRef, useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  InputBase,
  Typography,
  Avatar,
  Button,
  Popover,
} from "@mui/material";
import { experimentalStyled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation, useNavigate } from "react-router";
import useAuth from "../../hooks/useAuth";
import SnackbarProperties from "../SnackbarProperties";
import { useSnackbar } from "notistack";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { green } from "@mui/material/colors";
import NavSection from '../NavSection';
import { getRole } from '../helper';
const TopNavbarRoot = experimentalStyled(AppBar)(
  ({ theme, isSmallScreen, sidebarOpen }) => ({
    backgroundColor: "white",
    boxShadow: 4,
    color: green[500],
    position: "fixed",
    padding: "5px",
    top: 0,
    left: isSmallScreen ? '0px' : (sidebarOpen ? '250px' : '120px'), // Adjust based on sidebar state and screen size
    width: isSmallScreen ? '100%' : `calc(100% - ${sidebarOpen ? '250px' : '120px'})`, // Adjust width based on 
    zIndex: theme.zIndex.appBar,
  })
);

const TopNavbar = ({ isSmallScreen, sidebarOpen}) => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const [popoverOpen, setPopoverOpen] = useState(false);
  const anchorRef = useRef(null);

  const handlePopoverOpen = () => {
    setPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };

  const handleLogout = async () => {
    try {
      handlePopoverClose();
      await logout();
      navigate("/");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unable to logout", SnackbarProperties("error"));
    }
  };
  let arrayAccess = [
    {
      title: 'Profile',
      roles: ["Admin", "SuperAdmin", "Standard", "EnterpriseAdmin"],
      pathname: '/profile',
      items: []
    },
    {
      title: 'Change Password',
      roles: ["Admin", "SuperAdmin", "Standard", "EnterpriseAdmin"],
      pathname: '/resetpassword',
      items: []
    },
    // {
    //   title: 'Refer a Friend',
    //   roles: ["Admin", "SuperAdmin", "Standard", "EnterpriseAdmin"],
    //   pathname: '/refer',
    //   items: []
    // },
    {
      title: 'Manage In App Purchase',
      roles: ["Admin"],
      pathname: '/Subscription',
      items: []
    },
  ]
  return (
    <TopNavbarRoot isSmallScreen={isSmallScreen} sidebarOpen={sidebarOpen}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: isSmallScreen ? "flex-end" : "space-between",
          alignItems: "center",
          paddingX: 2,
        }}
      >
        {!isSmallScreen && (
          <Box sx={{ display: "flex", alignItems: "center", marginRight: 2 }}>
            {/* User Profile */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: 2,
                boxShadow: 4,
                padding: 1,
                borderRadius: 2,
              }}
            >
              <Typography
                sx={{ mr: 2, color: "black", textTransform: "capitalize" }}
              >
                <b>{localStorage.getItem("fullName")}</b>
              </Typography>
              <Avatar
                ref={anchorRef}
                sx={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  background: green[500],
                }}
                onClick={handlePopoverOpen}
              >
                <AccountCircleIcon sx={{ color: "white" }} />
              </Avatar>
              {/* Account Popover */}
              <Popover
                open={popoverOpen}
                anchorEl={anchorRef.current}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                sx={{ mt: 1 }}
              >
               
                <Box sx={{ mt: 1, p: 2 }}>
                  {arrayAccess.map((section) => {
                    const showRoute = section.roles.some((role) =>
                      getRole(role)
                    );
                    if (!showRoute) {
                      return "";
                    } else {
                      return (
                        <NavSection
                          key={section.title}
                          pathname={location.pathname}
                          onClick={section.onClick}
                          sx={{
                            "& + &": {
                              mt: 0,
                            },
                          }}
                          {...section}
                        />
                      );
                    }
                  })}
                </Box>
                
              </Popover>
            </Box>
            {/* Search Bar */}
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                alignItems: "center",
                border: "1px solid gray",
                borderRadius: "5px",
                boxShadow: 4,
                backgroundColor: "white",
                marginLeft: 2,
              }}
            >
              <IconButton size="large" color="inherit">
                <SearchIcon />
              </IconButton>
              <InputBase
                placeholder="Search…"
                sx={{ ml: 1, flex: 1, color: "black" }}
              />
            </Box>
          </Box>
        )}
        {/* LogOut */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            boxShadow: 4,
            padding: 1,
            borderRadius: 2,
          }}
        >
          <Button
            startIcon={<LogoutIcon />}
            sx={{ color: green[500] }}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </Toolbar>
    </TopNavbarRoot>
  );
};

export default TopNavbar;
