import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, TextField, Button } from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import moment from 'moment';

const applyPagination = (users, page, limit) => users.slice(page * limit, page * limit + limit);

const PointProtokolTable = ({ arrayData, ...other }) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(200);

  useEffect(() => {
  }, [arrayData])

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const paginatedCustomers = applyPagination(arrayData, page, limit);

  return (
    <Card {...other}>
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table size="medium">
            <TableHead>
              <TableRow>

                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Date
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Book Name
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Point Type
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Total Point
                  </Typography>
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedCustomers.map((data) => {

                return (
                  <TableRow hover key={data.id}>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {data.createdAt ? moment(data.createdAt).format("DD/MM/YYYY") : "-"}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {data.bookName || "-"}
                      </Typography>
                    </TableCell>

                    <TableCell onClick={(e) => { }}>
                      <Typography color="textPrimary" variant="subtitle2">
                        {data.description || "-"}
                      </Typography>
                    </TableCell>

                    <TableCell onClick={(e) => { }}>
                      <Typography color="textPrimary" variant="subtitle2">
                        {data.aiPointsTotal || "-"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={arrayData.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[50, 100, 200]}
      />
    </Card>
  );
};

PointProtokolTable.propTypes = {
  arrayData: PropTypes.array.isRequired
};

export default PointProtokolTable;
