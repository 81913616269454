import React,{ useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Typography } from '@mui/material';
import Logo from '../../images/logo.png';
import gtm from '../../lib/gtm';
import ForgotResetPass from '../../components/authentication/login/ForgotResetPass';


const ForgotReset = (props) => {

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#f5f3f2",
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8
            }}
          >
            <img
              src={Logo}
              alt="Logo"
              height="100"
            />

          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    New Password
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    Set new password for your account.
                  </Typography>
                </div>

              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                <ForgotResetPass regex={props.regex} />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default ForgotReset;
