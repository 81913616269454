import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Card, Container, Typography, Tabs, Tab, Divider, Button, Backdrop, CircularProgress } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Logo from '../../images/logo.png';
import useAuth from '../../hooks/useAuth';
import gtm from '../../lib/gtm';
import SnackbarProperties from '../../components/SnackbarProperties';
import CustomTextField from '../../components/CustomTextField'; // Assuming you have this component styled
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import axios from 'axios'; // Ensure axios is imported
import { useNavigate } from 'react-router-dom';
import useIsMountedRef from '../../hooks/useIsMountedRef';

// Inside your component

const Login = () => {
  const isMountedRef = useIsMountedRef();
  const navigate = useNavigate();
  const { isExpired, login } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [emailSubmitted, setEmailSubmitted] = useState(false); // Track email submission

  useEffect(() => {
    if (isExpired) {
      enqueueSnackbar("Your Login is expired, Please login again", SnackbarProperties("error"));
    }
    gtm.push({ event: 'page_view' });
  }, [isExpired, enqueueSnackbar]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSwipeChange = (index) => {
    setActiveTab(index);
  };

  const handleGoogleLogin = () => {
    enqueueSnackbar("Google login is not yet implemented", SnackbarProperties("warning"));
  };

  const handleAppleLogin = () => {
    enqueueSnackbar("Apple login is not yet implemented", SnackbarProperties("warning"));
  };

  const handleEmailSubmit = () => {
    setEmailSubmitted(true);
  };

  const handleSubmitSignUp = async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
    setIsLoading(true);
    try {
      const data = {
        userName: values.username,
        email: values.email,
        password: values.password,
        ysnNewsletter: values.ysnNewsletter,
      };
      const response = await axios.post("/apis/Users/add", data);

      if (response.data && response.data.success) {
        resetForm();
        setStatus({ success: true });
        enqueueSnackbar("Registered successfully", SnackbarProperties("success"));
        
      } else {
        enqueueSnackbar(response.data.message, SnackbarProperties("error"));
        setStatus({ success: false });
        setErrors({ submit: response.message });
      }
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      setErrors({ submit: err.message });
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  const handleSubmitLogin = async (values, { setErrors, setStatus, setSubmitting }) => {
    setIsLoading(true);
    try {
      var response = await login(values.email, values.password);

      if (response !== undefined && response.success && isMountedRef.current) {
        enqueueSnackbar("Login successful", SnackbarProperties("success"));
        setStatus({ success: true });
        setSubmitting(false);
      } else {
        enqueueSnackbar(response.data.message, SnackbarProperties("error"));
        setStatus({ success: false });
        setErrors({ submit: response.message });
        setEmailSubmitted(false); // Reset to show email field and "Continue" button
      }
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      setErrors({ submit: err.message });
      setEmailSubmitted(false); // Reset to show email field and "Continue" button
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f5f3f2",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        px: 2,
      }}
    >
      <Card
        sx={{
          maxWidth: 400,
          width: '100%',
          p: 3,
          boxShadow: 4,
          borderRadius: '10px',
        }}
      >
        <Box sx={{ textAlign: 'center', mb: 2 }}>
          <img src={Logo} alt="Logo" height={100} />
          <Typography variant="h5" sx={{ mt: 1 }}>
            Big Stuff Learning
          </Typography>
        </Box>

        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          variant="fullWidth"
          sx={{
            background: '#e9e9eb',
            padding: '10px',
            borderRadius: '10px',
            '& .MuiTab-root': {
              margin: '0 10px',
              textTransform: 'none',
              color: 'black',
            },
            '& .MuiTabs-indicator': {
              display: 'none',
            },
            '& .MuiTab-root.Mui-selected': {
              backgroundColor: 'black',
              borderRadius: '15px',
              color: 'white',
              padding: '0px 20px',
            },
          }}
        >
          <Tab label="Login" />
          <Tab label="Sign Up" />
        </Tabs>
        <SwipeableViews index={activeTab} onChangeIndex={handleSwipeChange}>
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Typography variant="h5">Welcome,</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Sign in to continue!
            </Typography>
          </Box>

          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Typography variant="h5">Sign Up</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Sign up to get started!
            </Typography>
          </Box>
        </SwipeableViews>
        <SwipeableViews index={activeTab} onChangeIndex={handleSwipeChange}>
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().max(255).required('Email is required').email('Must be a valid email'),
                password: Yup.string().min(8, 'Password must be at least 8 characters long').required('Password is required'),
              })}
              onSubmit={handleSubmitLogin}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  
                      <CustomTextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Email"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        variant="outlined"
                        sx={{ mb: 2 }}
                      />
                      
                      <CustomTextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Password"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        variant="outlined"
                        type="password"
                        sx={{ mb: 2 }}
                      />
                      <Box sx={{ mt: 2 }}>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          sx={{ borderRadius: '10px' }}
                        >
                          Login
                        </Button>
                      </Box>
                   
                </form>
              )}
            </Formik>
          </Box>

          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Formik
              initialValues={{
                username: '',
                email: '',
                password: '',
                confirmPassword: '',
                ysnNewsletter: 0,
              }}
              validationSchema={Yup.object().shape({
                username: Yup.string().max(255).required('User name is required'),
                email: Yup.string().max(255).required('Email is required').email('Must be a valid email'),
                password: Yup.string()
                  .min(8, 'Password must be at least 8 characters long')
                  .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
                  .matches(/[0-9]/, 'Password must contain at least one number')
                  .required('Password is required'),
                confirmPassword: Yup.string()
                  .required('Confirm password is required')
                  .oneOf([Yup.ref('password')], 'Passwords do not match'),
              })}
              onSubmit={handleSubmitSignUp}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <CustomTextField
                    error={Boolean(touched.username && errors.username)}
                    fullWidth
                    helperText={touched.username && errors.username}
                    label="User Name"
                    name="username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                    variant="outlined"
                    sx={{ mb: 2 }}
                  />
                  <CustomTextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                    sx={{ mb: 2 }}
                  />
                  <CustomTextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    variant="outlined"
                    type="password"
                    sx={{ mb: 2 }}
                  />
                  <CustomTextField
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    fullWidth
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    label="Confirm Password"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                    variant="outlined"
                    type="password"
                    sx={{ mb: 2 }}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <input
                      type="checkbox"
                      name="ysnNewsletter"
                      onChange={handleChange}
                      checked={values.ysnNewsletter}
                      style={{ marginRight: 8 }}
                    />
                    <Typography variant="body2">Subscribe to our newsletter</Typography>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      sx={{ borderRadius: '10px' }}
                    >
                      Sign Up
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </SwipeableViews>

        <Divider sx={{ my: 2 }} />

        <Box sx={{ textAlign: 'center' }}>
          <Button
            startIcon={<GoogleIcon />}
            variant="outlined"
            onClick={handleGoogleLogin}
            sx={{ mb: 1, borderRadius: '10px', width: '100%' }}
          >
            Sign In with Google
          </Button>
          <Button
            startIcon={<AppleIcon />}
            variant="outlined"
            onClick={handleAppleLogin}
            sx={{ borderRadius: '10px', width: '100%' }}
          >
            Sign In with Apple
          </Button>
        </Box>
      </Card>

      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default Login;
