import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material/styles';
import DashboardNavbar from './DashboardNavbar';
import TopNavbar from './TopNavbar';
import { useMediaQuery } from '@mui/material';

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme, isSmallScreen, sidebarOpen }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '60px', // Padding for top navbar
  marginLeft: isSmallScreen ? '0px' : (sidebarOpen ? '250px' : '120px'),
  transition: 'margin-left 0.3s ease', // Smooth transition
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
  padding: '20px', // Add padding for content separation
});

const DashboardLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Track sidebar open state
  const isSmallScreen = useMediaQuery('(max-width:700px)');

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar 
        onSidebarToggle={() => setIsSidebarOpen(!isSidebarOpen)} // Toggle sidebar state
        sidebarOpen={isSidebarOpen} // Pass state to Navbar
        isMobileSidebarOpen={isSidebarOpen}
      />
      <TopNavbar isSmallScreen={isSmallScreen} sidebarOpen={isSidebarOpen}/>
      <DashboardLayoutWrapper isSmallScreen={isSmallScreen} sidebarOpen={isSidebarOpen}>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
