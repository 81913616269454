import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, IconButton } from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import Trash from '../../icons/Trash';
import PencilAltIcon from '../../icons/PencilAlt';
import moment from 'moment';

const applyPagination = (users, page, limit) => users.slice(page * limit, page * limit + limit);

const LicenceListTable = ({ arrayData, onUpdate, ...other }) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(200);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const paginatedCustomers = applyPagination(arrayData, page, limit);

  return (
    <Card {...other}>
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    From
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    To
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Total Users
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Total Added Users
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedCustomers.map((data) => {
                return (
                  <TableRow hover key={data.ePackageID} >

                    <TableCell onClick={(e) => { }}>
                      <Typography color="textPrimary" variant="subtitle2">
                        {data.datFrom ? moment(data.datFrom).format("DD/MM/YYYY") : "-"}
                      </Typography>
                    </TableCell>

                    <TableCell onClick={(e) => { }}>
                      <Typography color="textPrimary" variant="subtitle2">
                        {data.datTo ? moment(data.datTo).format("DD/MM/YYYY") : "-"}
                      </Typography>
                    </TableCell>

                    <TableCell onClick={(e) => { }}>
                      <Typography color="textPrimary" variant="subtitle2">
                        {data.NumerOfUsers}
                      </Typography>
                    </TableCell>

                    <TableCell onClick={(e) => { }}>
                      <Typography color="textPrimary" variant="subtitle2">
                        {data.ysnUserInTable}
                      </Typography>
                    </TableCell>

                    <TableCell align="right" sx={{ pt: 1, pb: 1 }}>
                      <IconButton size="large" onClick={(e) => { onUpdate(data) }} >
                        <PencilAltIcon fontSize="small" />
                      </IconButton>
                      {/* <IconButton size="large" onClick={(e) => { onDelete(data) }} >
                        <Trash fontSize="small" />
                      </IconButton> */}
                    </TableCell>

                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={arrayData.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[50, 100, 200]}
      />
    </Card>
  );
};

LicenceListTable.propTypes = {
  arrayData: PropTypes.array.isRequired
};

export default LicenceListTable;
