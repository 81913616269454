import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Divider, Card, Grid, TextField, Backdrop, CircularProgress } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import api from '../../lib/axios';
import SnackbarProperties from '../../components/SnackbarProperties';
import { useSnackbar } from 'notistack';

export const ManageEnterpriseAlert = ({ enterpriseID, ...props }) => {
    const { enqueueSnackbar } = useSnackbar()
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");

    useEffect(() => {
        if (enterpriseID != "") {
            getData()
        }
    }, []);


    function getData() {
        setIsLoading(true)
        api.get('/apis/Enterprises/byId/' + enterpriseID).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setName(resData.enterprise_short_name)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });

    }

    async function apiCalling(name) {
        var data = {
            enterprise_short_name: name
        }
        if (enterpriseID != "") {
            return await api.put('/apis/Enterprises/update/' + enterpriseID, data)
        } else {
            return await api.post('/apis/Enterprises/add/', data)
        }
    }

    return (
        <>
            <Dialog
                open
                fullWidth
                aria-labelledby="alert-dialog-title"
            >
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: name,
                        submit: null
                    }}
                    validationSchema={Yup
                        .object()
                        .shape({
                            name: Yup.string().required('Channel name is required'),
                        })}
                    onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                        try {
                            setIsLoading(true)
                            var data = await apiCalling(values.name)
                            setIsLoading(false)
                            if (data !== undefined && data.data.success) {
                                resetForm();
                                setStatus({ success: true });
                                setSubmitting(false);
                                enqueueSnackbar('Channel ' + (enterpriseID == "" ? "added" : "updated"), SnackbarProperties("success"));
                                props.updateConfirmAlert(true, "");
                            } else {
                                setStatus({ success: false });
                                setErrors({ submit: data.data.message });
                                setSubmitting(false);
                                enqueueSnackbar(data.data.message, SnackbarProperties("error"));
                            }
                        } catch (err) {
                            console.error(err);
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values }) => (
                        <form
                            onSubmit={handleSubmit}
                        >
                            <Card>
                                <Box sx={{ p: 3 }}>
                                    <Grid container spacing={3} >
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                error={Boolean(touched.name && errors.name)}
                                                fullWidth
                                                helperText={touched.name && errors.name}
                                                label="Channel Name"
                                                name="name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.name}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item />
                                    </Grid>
                                    <Divider sx={{ my: 2 }} />
                                    <Box sx={{ alignItems: 'center', display: 'flex' }} >
                                        <Button
                                            color="primary"
                                            variant="text"
                                            type="reset"
                                            onClick={(e) => {
                                                props.updateConfirmAlert(false, "");
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Box sx={{ flexGrow: 1 }} />
                                        <Button
                                            color="primary"
                                            sx={{ ml: 1, fontSize: "12px", fontWeight: "normal" }}
                                            disabled={isSubmitting}
                                            type="submit"
                                            variant="contained">

                                            Save
                                        </Button>

                                    </Box>
                                </Box>
                            </Card>
                        </form>
                    )}
                </Formik>
                <Backdrop style={{ zIndex: 1 }} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        </>
    )
}