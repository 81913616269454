import React, { useState, useEffect } from 'react';
import { Box, Button, Container, Grid, Typography, Card, InputAdornment, Backdrop, CircularProgress, TextField, Stack, Paper } from '@mui/material';
import SearchIcon from '../../icons/Search';
import PlusIcon from '../../icons/Plus';
import api from '../../lib/axios';
import { ConfirmAlert } from '../../customControls/confirmAlert';
import EnterpriseListTable from './EnterpriseListTable';
import SnackbarProperties from '../../components/SnackbarProperties';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import BookListTable from './BookListTable';
import { ManageEnterpriseAlert } from './ManageEnterpriseAlert';
import { ManageLicenceeAlert } from './ManageLicenceeAlert';
import { ManageUserAlert } from './ManageUserAlert';
import LicenceListTable from './LicenceListTable';
import UserListTable from './UserListTable';

export const EnterpriseList = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [arrayData, setArrayData] = useState([]);
  const [query, setQuery] = useState("");
  const [filteredArrayData, setFilteredArrayData] = useState([]);
  const [listType, setListType] = useState("book");
  const [arrayBook, setArrayBook] = useState([]);
  const [arrayLicence, setArrayLicence] = useState([]);
  const [arrayUser, setArrayUser] = useState([]);
  const [selectedEnterpriseId, setSelectedEnterpriseId] = useState("");
  const [selectedDataId, setSelectedDataId] = useState("");
  const [selectedUserObj, setSelectedUserObj] = useState(null);
  const [manageEnterpriseAlertShouldOpen, setManageEnterpriseAlertShouldOpen] = useState(false);
  const [manageLicenceAlertShouldOpen, setManageLicenceAlertShouldOpen] = useState(false);
  const [manageUserAlertShouldOpen, setManageUserAlertShouldOpen] = useState(false);
  const [objDeleteConfirmation, seObjDeleteConfirmation] = useState({
    deleteMessage: "Are you sure you want to delete?",
    deleteConfirmationShouldOpen: false,
    selectedDeleteId: "",
    deleteType: ""
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setIsLoading(true);
    api.get('/apis/Enterprises/allEnterprises')
      .then((res) => {
        if (res.data.success) {
          const resData = res.data.data;
          const filterArray = applyFilters(resData, query);
          setArrayData(res.data.data);
          setFilteredArrayData(filterArray);
          setQuery("");
          var enterpriseID = localStorage.getItem("selectedEnterpriseId")
          if (enterpriseID === null || enterpriseID == "") {
            enterpriseID = resData.length > 0 ? resData[0].enterpriseID : ""
            localStorage.setItem("selectedEnterpriseId", enterpriseID);
          }
          if (enterpriseID != "") {
            setSelectedEnterpriseId(enterpriseID)
            getBookByEnterprise(enterpriseID)
          }

        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBookByEnterprise = (enterpriseID) => {
    setIsLoading(true);
    api.get('/apis/Books/getAllEnterpriseBook/' + enterpriseID).then((res) => {
      if (res.data.success) {
        const resData = res.data.data;
        setArrayBook(resData)
      }
      setIsLoading(false);
    }).catch((error) => {
      console.log(error);
    });
  };

  const getLicenceByEnterprise = (enterpriseID) => {
    setIsLoading(true);
    api.get('/apis/EnterpriseLicencePackages/byEnterprise/' + enterpriseID).then((res) => {
      if (res.data.success) {
        const resData = res.data.data;
        setArrayLicence(resData)
      }
      setIsLoading(false);
    }).catch((error) => {
      console.log(error);
    });
  };

  const getUserByEnterprise = (enterpriseID) => {
    setIsLoading(true);
    api.get('/apis/UserEnterprise/byEnterprise/' + enterpriseID).then((res) => {
      if (res.data.success) {
        const resData = res.data.data;
        setArrayUser(resData)
      }
      setIsLoading(false);
    }).catch((error) => {
      console.log(error);
    });
  };

  const onEnterpriseClick = (data) => {
    setListType("book")
    localStorage.setItem("selectedEnterpriseId", data.enterpriseID);
    setSelectedEnterpriseId(data.enterpriseID)
    getBookByEnterprise(data.enterpriseID)
  };

  const onBookListClick = (data) => {
    setListType("book")
    localStorage.setItem("selectedEnterpriseId", data.enterpriseID);
    setSelectedEnterpriseId(data.enterpriseID)
    getBookByEnterprise(data.enterpriseID)
  };

  const onEditEnterpriseClick = (data) => {
    setSelectedDataId(data.enterpriseID)
    setManageEnterpriseAlertShouldOpen(true)
  };

  const onAddLicenceClick = (enterpriseID) => {
    setListType("licence")
    localStorage.setItem("selectedEnterpriseId", enterpriseID);
    setSelectedEnterpriseId(enterpriseID)
    getLicenceByEnterprise(enterpriseID)
  };

  const onAddUserClick = (enterpriseID) => {
    setListType("user")
    localStorage.setItem("selectedEnterpriseId", enterpriseID);
    setSelectedEnterpriseId(enterpriseID)
    getUserByEnterprise(enterpriseID)
  };


  const onUpdateLicenceClick = (data) => {
    setSelectedDataId(data.ePackageID)
    setManageLicenceAlertShouldOpen(true)
  };

  const onUpdateUserClick = (data) => {
    setSelectedUserObj(data)
    setManageUserAlertShouldOpen(true)
  };

  const onDeleteUserClick = (data) => {
    seObjDeleteConfirmation({
      deleteMessage: "Are you sure you want to remove user from enterprise?",
      deleteConfirmationShouldOpen: true,
      selectedDeleteId: data.userID,
      deleteType: "4"
    })
  };

  const onDeleteBookClick = (e) => {
    seObjDeleteConfirmation({
      deleteMessage: "Are you sure you want to remove book from enterprise?",
      deleteConfirmationShouldOpen: true,
      selectedDeleteId: e,
      deleteType: "2"
    })
  };

  const deleteConfirmation = (e) => {
    seObjDeleteConfirmation({
      deleteMessage: "Are you sure you want to delete?",
      deleteConfirmationShouldOpen: true,
      selectedDeleteId: e,
      deleteType: "1"
    })
  };

  const deleteRecord = () => {
    setIsLoading(true);
    api.delete('/apis/Enterprises/delete/' + objDeleteConfirmation.selectedDeleteId)
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar("Channel deleted", SnackbarProperties("success"));
          getData();
        } else {
          setIsLoading(false);
          enqueueSnackbar(res.data.message, SnackbarProperties("error"));
        }
        seObjDeleteConfirmation(prevState => ({
          ...prevState,
          deleteConfirmationShouldOpen: false,
          selectedDeleteId: "",
          deleteType: ""
        }))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeBook = () => {
    setIsLoading(true);
    api.delete('/apis/BookToEnterpriseCategory/delete/' + objDeleteConfirmation.selectedDeleteId + "/" + selectedEnterpriseId)
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar("Book removed", SnackbarProperties("success"));
          getBookByEnterprise(selectedEnterpriseId)
        } else {
          setIsLoading(false);
          enqueueSnackbar(res.data.message, SnackbarProperties("error"));
        }
        seObjDeleteConfirmation(prevState => ({
          ...prevState,
          deleteConfirmationShouldOpen: false,
          selectedDeleteId: "",
          deleteType: ""
        }))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeUser = () => {
    setIsLoading(true);
    api.delete('/apis/UserEnterprise/delete/' + objDeleteConfirmation.selectedDeleteId + "/" + selectedEnterpriseId)
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar("User removed", SnackbarProperties("success"));
          getUserByEnterprise(selectedEnterpriseId)
        } else {
          setIsLoading(false);
          enqueueSnackbar(res.data.message, SnackbarProperties("error"));
        }
        seObjDeleteConfirmation(prevState => ({
          ...prevState,
          deleteConfirmationShouldOpen: false,
          selectedDeleteId: "",
          deleteType: ""
        }))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateConfirmAlert = (value) => {
    if (value) {
      if (objDeleteConfirmation.deleteType == "1") {
        deleteRecord();
      } else if (objDeleteConfirmation.deleteType == "2") {
        // removeBook()
      }
      else if (objDeleteConfirmation.deleteType == "3") {
        // removeLicence()
      }
      else if (objDeleteConfirmation.deleteType == "4") {
        removeUser()
      }

    }
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    const filterArray = applyFilters(arrayData, event.target.value);
    setFilteredArrayData(filterArray);
  };

  const applyFilters = (arrayDataAll, query) => {
    const finalArray = arrayDataAll.filter((dataObj) => {
      let matches = true;
      const properties = ['enterprise_short_name'];
      let containsQuery = false;
      properties.forEach((property) => {
        const str = dataObj[property] + "";
        if (str.toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });
      if (!containsQuery) {
        matches = false;
      }
      return matches;
    });
    return finalArray.length > 0 ? [...finalArray] : [];
  };

  return (
    <>
      <Box sx={{ backgroundColor: 'background.default', height: '100%', py: 2 }}>
        <Container maxWidth={'xxl'} minWidth='100%' sx={{ overflow: 'auto', height: '100%', borderRadius: 2, pt: 1, pb: 1 }}>
          <Stack direction="row" sx={{ height: 'calc(100%)' }} spacing={1}>
            <Paper sx={{ display: 'flex', flexDirection: 'column', flex: '0 0 35%', overflow: 'auto' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', height: '50px', alignItems: 'center', padding: "15px", gap: "5px" }}>
                <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold' >
                  List of Channels
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Button color="primary" startIcon={<PlusIcon fontSize="small" />} sx={{ fontSize: "12px", fontWeight: "normal" }} variant="contained"
                  onClick={(e) => {
                    setSelectedDataId("")
                    setManageEnterpriseAlertShouldOpen(true)
                  }} >
                  Add Channel
                </Button>
              </Box>

              <Box sx={{ height: "32px", borderRadius: '15px', borderWidth: '1px', borderStyle: 'solid', borderColor: '#D5D5D5', display: 'flex', alignItems: 'center', padding: "15px", margin: "8px", }}>
                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true, style: { fontSize: '13px', fontWeight: 'normal', color: '#262B40' }
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search"
                  value={query}
                  sx={{ textAlign: 'left', fontSize: '50px', fontWeight: 'bold', }}
                  variant="standard"
                />
              </Box>
              <EnterpriseListTable arrayData={filteredArrayData} selectedDataId={selectedEnterpriseId} onDelete={deleteConfirmation} onEdit={onEditEnterpriseClick} onEnterpriseClick={onEnterpriseClick} onBookList={onBookListClick} onAddLicence={onAddLicenceClick} onAddUser={onAddUserClick} sx={{ borderRight: '1px solid #E1E3E8' }} />
            </Paper>
            <Paper sx={{ flex: '0 0 65%', overflow: 'auto' }}>
              {listType == "book" && <Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', height: '50px', alignItems: 'center', padding: "15px", gap: "5px" }}>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold' >
                    List of Books
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  {/* <Button color="primary" startIcon={<PlusIcon fontSize="small" />} sx={{ fontSize: "12px", fontWeight: "normal" }} variant="contained"
                    onClick={(e) => {

                    }} >
                    Add Book
                  </Button> */}
                </Box>
                <BookListTable arrayData={arrayBook} onDelete={onDeleteBookClick} /></Box>}
              {listType == "licence" &&
                <Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center', height: '50px', alignItems: 'center', padding: "15px", gap: "5px" }}>
                    <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold' >
                      List of Licences
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button color="primary" startIcon={<PlusIcon fontSize="small" />} sx={{ fontSize: "12px", fontWeight: "normal" }} variant="contained"
                      onClick={(e) => {
                        setSelectedDataId("")
                        setManageLicenceAlertShouldOpen(true)
                      }} >
                      Add Licence
                    </Button>
                  </Box>
                  <LicenceListTable arrayData={arrayLicence} onUpdate={onUpdateLicenceClick} />
                </Box>}
              {listType == "user" &&
                <Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center', height: '50px', alignItems: 'center', padding: "15px", gap: "5px" }}>
                    <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold' >
                      List of Users
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button color="primary" startIcon={<PlusIcon fontSize="small" />} sx={{ fontSize: "12px", fontWeight: "normal" }} variant="contained"
                      onClick={(e) => {
                        setSelectedUserObj(null)
                        setManageUserAlertShouldOpen(true)
                      }} >
                      Add User
                    </Button>
                  </Box>
                  <UserListTable arrayData={arrayUser} onDelete={onDeleteUserClick} onUpdate={onUpdateUserClick} />
                </Box>}
            </Paper>
          </Stack>
        </Container>
        {manageEnterpriseAlertShouldOpen && (
          <ManageEnterpriseAlert
            enterpriseID={selectedDataId}
            updateConfirmAlert={(e, value) => {
              setSelectedDataId("")
              setManageEnterpriseAlertShouldOpen(false)
              if (e) {
                getData()
              }
            }}
          />
        )}
        {manageLicenceAlertShouldOpen && (
          <ManageLicenceeAlert
            enterpriseID={selectedEnterpriseId}
            ePackageID={selectedDataId}
            updateConfirmAlert={(e, value) => {
              setSelectedDataId("")
              setManageLicenceAlertShouldOpen(false)
              if (e) {
                getLicenceByEnterprise(selectedEnterpriseId)
              }
            }}
          />
        )}
        {manageUserAlertShouldOpen && (
          <ManageUserAlert
            enterpriseID={selectedEnterpriseId}
            obj={selectedUserObj}
            updateConfirmAlert={(e, value) => {
              setSelectedUserObj(null)
              setManageUserAlertShouldOpen(false)
              if (e) {
                getUserByEnterprise(selectedEnterpriseId)
              }
            }}
          />
        )}
        {objDeleteConfirmation.deleteConfirmationShouldOpen && (
          <ConfirmAlert
            title={"Confirmation"}
            body={objDeleteConfirmation.deleteMessage}
            updateConfirmAlert={(e, value) => {
              seObjDeleteConfirmation(prevState => ({
                ...prevState,
                deleteConfirmationShouldOpen: false
              }));
              updateConfirmAlert(value);
            }}
          />
        )}

        <Backdrop style={{ zIndex: 1 }} open={isLoading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box >
    </>
  );
};
