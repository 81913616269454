const SnackbarProperties = (variant) => {
    return {
        anchorOrigin: {
            horizontal: 'right',
            vertical: 'bottom'
        },
        variant: variant,
        disableWindowBlurListener: true,
        autoHideDuration: 2000
    };
};

export default SnackbarProperties;