import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, } from '@mui/material';
import Trash from '../../icons/Trash';
import PencilAltIcon from '../../icons/PencilAlt';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Scrollbar from '../../components/Scrollbar';

const UserListTable = (props) => {
  const { arrayData, onDelete, onUpdate, ...other } = props;

  return (
    <Scrollbar {...other}>
      <Box >
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                  Email
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                  Admin
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {arrayData.map((data, index) => {
              return (
                <>
                  <TableRow hover sx={{ background: index % 2 === 0 ? '#ffffff' : '#F5F6F8', pt: 1, pb: 1 }} key={data._id}>
                    <TableCell>
                      <Typography color="textPrimary" variant="subtitle2" >
                        {data.email}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      {data.ysnAdmin ? <CheckIcon /> : <CloseIcon />}
                    </TableCell>

                    <TableCell align="right" sx={{ pt: 1, pb: 1 }}>
                      <IconButton size="large" onClick={(e) => { onUpdate(data) }} >
                        <PencilAltIcon fontSize="small" />
                      </IconButton>
                      <IconButton size="large" onClick={(e) => { onDelete(data) }} >
                        <Trash fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Scrollbar>
  );
};

UserListTable.propTypes = {
  arrayData: PropTypes.array.isRequired
};

export default UserListTable;
