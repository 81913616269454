import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import moment from 'moment';
import Scrollbar from '../../components/Scrollbar';

const applyPagination = (users, page, limit) => users.slice(page * limit, page * limit + limit);

const SubscriptionTable = (props) => {
  const { arrayData, ...other } = props;
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(200);


  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const paginatedCustomers = applyPagination(arrayData, page, limit);

  return (
    <Card {...other}>
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    User
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Subscription
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Buy Date
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Expire Date
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Total Points
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography color="#615D5D" variant="primary" fontFamily={"Roboto"} fontSize={'15px'} fontWeight='bold'>
                    Remaining Points
                  </Typography>
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedCustomers.map((data) => {

                return (
                  <TableRow hover key={data.id} >

                    <TableCell>
                      <Typography color="textPrimary" variant="subtitle2" >
                        {data.email || "-"}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography color="textPrimary" variant="subtitle2" >
                        {data.productName || "-"}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography color="textPrimary" variant="subtitle2" >
                        {data.dateBought ? moment(data.dateBought).format("DD/MM/YY") : "-"}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography color="textPrimary" variant="subtitle2" >
                        {data.expireDate ? moment(data.expireDate).format("DD/MM/YY") : "-"}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography color="textPrimary" variant="subtitle2" >
                        {data.aiPointsContingent || "-"}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography color="textPrimary" variant="subtitle2" >
                        {data.aiPointBalance || "-"}
                      </Typography>
                    </TableCell>

                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={paginatedCustomers.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[50, 100, 200]}
      />
    </Card>
  );
};

SubscriptionTable.propTypes = {
  arrayData: PropTypes.array.isRequired
};

export default SubscriptionTable;
