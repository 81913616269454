import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography, Card, InputAdornment, Backdrop, CircularProgress, TextField, Tab, Tabs, Divider } from '@mui/material';
import SearchIcon from '../../icons/Search';
import { withSnackbar } from 'notistack';
import api from '../../lib/axios';
import BookPublicReviewListTable from './BookPublicReviewListTable';
import BookListTable from './BookListTable';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { ConfirmAlert } from '../../customControls/confirmAlert';
import SnackbarProperties from '../../components/SnackbarProperties';

export const BookPublicList = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [arrayData, setArrayData] = useState([]);
  const [arrayProductData, setArrayProductData] = useState([]);
  const [filteredArrayProductData, setFilteredArrayProductData] = useState([]);
  const [query, setQuery] = useState("");
  const [filteredArrayData, setFilteredArrayData] = useState([]);
  const [currentTab, setCurrentTab] = useState("pending");
  const [objDeleteConfirmation, seObjDeleteConfirmation] = useState({
    deleteMessage: "Are you sure you want to delete?",
    deleteConfirmationShouldOpen: false,
    selectedDeleteId: "",
    deleteType: ""
  });
  const tabs = [
    { label: 'Pending', value: 'pending' },
    { label: 'Approved', value: 'approved' },
  ]
  useEffect(() => {
    const tab = localStorage.getItem("selectedPublicBookTab");
    if (tab === 'approved') {
      getProductData();
    } else {
      getData();
    }
  }, []);

  const getData = () => {
    setIsLoading(true);
    api.get('/apis/BookSalesRequest/pendingByUser').then((res) => {
      if (res.data.success) {
        const resData = res.data.data;
        const filterArray = applyFilters(resData, query);
        setArrayData(res.data.data);
        setFilteredArrayData(filterArray);
        setQuery("");
        setIsLoading(false);
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const getProductData = () => {
    setIsLoading(true);
    setCurrentTab("approved");
    api.get('/apis/Books/getPublicBook').then((res) => {
      if (res.data.success) {
        const resData = res.data.data;
        const filterArray = applyFilters(resData, query);
        setArrayProductData(res.data.data);
        setFilteredArrayProductData(filterArray);
        setQuery("");
        setIsLoading(false);
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const deleteProductBook = () => {
    setIsLoading(true);
    api.delete('/apis/booksProducts/delete/' + objDeleteConfirmation.selectedDeleteId)
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar("Book deleted", SnackbarProperties("success"));
          getProductData()
        } else {
          setIsLoading(false);
          enqueueSnackbar(res.data.message, SnackbarProperties("error"));
        }
        seObjDeleteConfirmation(prevState => ({
          ...prevState,
          deleteConfirmationShouldOpen: false,
          selectedDeleteId: "",
          deleteType: ""
        }))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeRequest = () => {
    setIsLoading(true);
    api.delete('/apis/BookSalesRequest/delete/' + objDeleteConfirmation.selectedDeleteId)
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar("Request deleted", SnackbarProperties("success"));
          getData()
        } else {
          setIsLoading(false);
          enqueueSnackbar(res.data.message, SnackbarProperties("error"));
        }
        seObjDeleteConfirmation(prevState => ({
          ...prevState,
          deleteConfirmationShouldOpen: false,
          selectedDeleteId: "",
          deleteType: ""
        }))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateConfirmAlert = (value) => {
    if (value) {
      if (objDeleteConfirmation.deleteType == "1") {
        removeRequest();
      } if (objDeleteConfirmation.deleteType == "2") {
        deleteProductBook()
      }

    }
  };


  const onDeleteRequest = (e) => {
    seObjDeleteConfirmation({
      deleteMessage: "Are you sure you want to remove book from review?",
      deleteConfirmationShouldOpen: true,
      selectedDeleteId: e,
      deleteType: "1"
    })
  };


  const onDeletePublickBookClick = (e) => {
    seObjDeleteConfirmation({
      deleteMessage: "Are you sure you want to remove book from public library?",
      deleteConfirmationShouldOpen: true,
      selectedDeleteId: e,
      deleteType: "2"
    })
  };

  const onEditBookClick = (data) => {
    navigate("/bookDownloadCategory/editPublicBook", {
      state: { bookID: data.bookdID, bookProductId: data.id }
    });
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    const filterArray = applyFilters(currentTab === 'pending' ? arrayData : arrayProductData, event.target.value);
    if (currentTab === 'pending') {
      setFilteredArrayData(filterArray);
    } else {
      setFilteredArrayProductData(filterArray);
    }
  };

  const applyFilters = (arrayDataAll, query) => {
    let finalArray = [];
    const filteredArraySite = arrayDataAll.filter((dataObj) => {
      let matches = true;
      const properties = ['bookName', 'title', 'creator', 'fullName'];
      let containsQuery = false;

      properties.forEach((property) => {
        const str = dataObj[property] + "";
        if (str.toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
      return matches;
    });
    finalArray = filteredArraySite.length > 0 ? [...filteredArraySite] : [];
    if (query === "") {
      return arrayDataAll;
    }
    return finalArray;
  };


  const handleTabsChange = (event, value) => {
    localStorage.setItem("selectedPublicBookTab", value);
    setCurrentTab(value);
    if (value === "pending") {
      getData();
    } else if (value === "approved") {
      getProductData();
    }
  };

  return (
    <>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 5 }}>
        <Container maxWidth={'xxl'}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">Public Book Manager</Typography>
            </Grid>
          </Grid>

          <Box sx={{ mt: '18px' }} md={4} xs={4}>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="#262B40"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  sx={{ fontWeight: 'normal', textTransform: "none" }}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </Box>
          <Divider />

          {currentTab === 'pending' && (
            <Card sx={{ mt: 3 }}>
              <Grid container sx={{ py: 2, px: 2 }} spacing={2}>
                <Grid
                  item
                  md={6}
                  xs={6}
                >
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      )
                    }}
                    onChange={handleQueryChange}
                    placeholder="Search"
                    value={query}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <BookPublicReviewListTable arrayData={filteredArrayData} onDelete={onDeleteRequest} />
            </Card>
          )}

          {currentTab === 'approved' && (
            <Card sx={{ mt: 3 }}>
              <Grid container sx={{ py: 2, px: 2 }} spacing={2}>
                <Grid
                  item
                  md={6}
                  xs={6}
                >
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      )
                    }}
                    onChange={handleQueryChange}
                    placeholder="Search"
                    value={query}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <BookListTable arrayData={filteredArrayProductData} onEdit={onEditBookClick} onDelete={onDeletePublickBookClick} />
            </Card>
          )}
        </Container>
        {objDeleteConfirmation.deleteConfirmationShouldOpen && (
          <ConfirmAlert
            title={"Confirmation"}
            body={objDeleteConfirmation.deleteMessage}
            updateConfirmAlert={(e, value) => {
              seObjDeleteConfirmation(prevState => ({
                ...prevState,
                deleteConfirmationShouldOpen: false
              }));
              updateConfirmAlert(value);
            }}
          />
        )}
        <Backdrop style={{ zIndex: 1 }} open={isLoading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  );
}
