import React, { useState, useEffect } from 'react';
import { Box, Button, Container, Grid, Typography, Card, InputAdornment, Backdrop, CircularProgress, TextField, Stack, Autocomplete } from '@mui/material';
import SearchIcon from '../../icons/Search';
import PlusIcon from '../../icons/Plus';
import api from '../../lib/axios';
import { ConfirmAlert } from '../../customControls/confirmAlert';
import BookDownloadCategoryTable from './BookDownloadCategoryTable';
import SnackbarProperties from '../../components/SnackbarProperties';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import BookListTable from './BookListTable';

export const BookDownloadCategoryList = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [arrayData, setArrayData] = useState([]);
  const [query, setQuery] = useState("");
  const [arrayBook, setArrayBook] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("0");
  const [selectedCategory, setSelectedCategory] = useState({ id: "0", name: "All" });
  const [selectedLanguage, setSelectedLanguage] = useState({ "id": "0", "name": "All" });
  const [objDeleteConfirmation, seObjDeleteConfirmation] = useState({
    deleteMessage: "Are you sure you want to delete?",
    deleteConfirmationShouldOpen: false,
    selectedDeleteId: "",
    deleteType: ""
  });

  const arrayLanguage = [
    {
      "id": "0",
      "name": "All"
    },
    {
      "id": "EN",
      "name": "English"
    },
    {
      "id": "DE",
      "name": "German",
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    var language = localStorage.getItem("selectedPublicLanguage");
    if (language == null || language == "") {
      language = "0"
    }
    var selectedLanguage = arrayLanguage.find(obj => obj.id == language)
    setSelectedLanguage(selectedLanguage)
    setIsLoading(true);
    const data = {
      language: "en"
    };
    api.post('/apis/BookDownloadCategory/getAll', data)
      .then((res) => {
        if (res.data.success) {
          const resData = res.data.data;
          var array = [{ id: "0", name: "All" }]
          var arrayCategory = [...array, ...resData]
          setArrayData(arrayCategory);

          var categoryId = localStorage.getItem("selectedPublicCategoryId");
          if (categoryId == null || categoryId == "") {
            categoryId = "0"
          }
          setSelectedCategoryId(categoryId)
          var selectedCategory = arrayCategory.find(obj => obj.id == categoryId)
          setSelectedCategory(selectedCategory)
          getBookByCategory(categoryId, language)
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBookByCategory = (categoryId, language) => {
    setIsLoading(true);
    const data = {
      language: language,
      categoryId: categoryId,
      text: query,
    };
    api.post('/apis/booksProducts/filter', data).then((res) => {
      if (res.data.success) {
        const resData = res.data.data;
        setArrayBook(resData)
      }
      setIsLoading(false);
    }).catch((error) => {
      console.log(error);
    });
  };

  const onCategoryClick = (data) => {
    localStorage.setItem("selectedPublicCategoryId", data.id);
    setSelectedCategory(data)
    setSelectedCategoryId(data.id)
    getBookByCategory(data.id, selectedLanguage.id)
  };

  const onDeleteBookClick = (e) => {
    seObjDeleteConfirmation({
      deleteMessage: "Are you sure you want to delete?",
      deleteConfirmationShouldOpen: true,
      selectedDeleteId: e,
      deleteType: "2"
    })
  };

  const onEditBookClick = (data) => {
    navigate("/bookDownloadCategory/editPublicBook",
      {
        state: { bookID: data.bookdID, bookProductId: data.id }
      });
  };

  const deleteConfirmation = (e) => {
    seObjDeleteConfirmation({
      deleteMessage: "Are you sure you want to delete?",
      deleteConfirmationShouldOpen: true,
      selectedDeleteId: e,
      deleteType: "1"
    })
  };

  const deleteRecord = () => {
    setIsLoading(true);
    api.delete('/apis/BookDownloadCategory/delete/' + objDeleteConfirmation.selectedDeleteId)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          enqueueSnackbar('Folder deleted', SnackbarProperties("success"));
          getData();
        } else {
          setIsLoading(false);
          enqueueSnackbar(res.data.message, SnackbarProperties("error"));
        }
        seObjDeleteConfirmation(prevState => ({
          ...prevState,
          deleteConfirmationShouldOpen: false,
          selectedDeleteId: "",
          deleteType: ""
        }))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteBook = () => {
    setIsLoading(true);
    api.delete('/apis/booksProducts/delete/' + objDeleteConfirmation.selectedDeleteId)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          enqueueSnackbar('Book deleted', SnackbarProperties("success"));
          getBookByCategory(selectedCategoryId, selectedLanguage.id)
        } else {
          setIsLoading(false);
          enqueueSnackbar(res.data.message, SnackbarProperties("error"));
        }
        seObjDeleteConfirmation(prevState => ({
          ...prevState,
          deleteConfirmationShouldOpen: false,
          selectedDeleteId: "",
          deleteType: ""
        }))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateConfirmAlert = (value) => {
    if (value) {
      if (objDeleteConfirmation.deleteType == "1") {
        deleteRecord();
      } if (objDeleteConfirmation.deleteType == "2") {
        deleteBook()
      }
    }
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  return (
    <>
      <Box sx={{ backgroundColor: 'background.default', height: '100%', py: 2 }}>
        <Container maxWidth={'xxl'} minWidth='100%' sx={{ overflow: 'hidden', height: '100%', borderRadius: 2 }}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Public Folders
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button color="primary" startIcon={<PlusIcon fontSize="small" />} sx={{ m: 1, fontSize: "12px", fontWeight: "normal" }} variant="contained"
                  onClick={(e) => {
                    navigate("/bookDownloadCategory/manage", {
                      state: { categoryId: "" }
                    });
                  }}
                >
                  Add Folder
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Card sx={{ mt: 3, overflow: 'auto', height: 'calc(100% - 60px)' }}>
            <Grid container sx={{ py: 2, px: 2 }} spacing={2}>
              <Grid item md={4} xs={4} >
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search..."
                  value={query}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} xs={3} >
                <Autocomplete
                  value={selectedCategory}
                  disableClearable={true}
                  getOptionLabel={(option) => option.name}
                  options={arrayData}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={(event, newValue) => {
                    setSelectedCategory(newValue)
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      name="selectedCategory"
                      variant="outlined"
                      label="Select Folder"
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} xs={3} >
                <Autocomplete
                  value={selectedLanguage}
                  disableClearable={true}
                  getOptionLabel={(option) => option.name}
                  options={arrayLanguage}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={(event, newValue) => {
                    setSelectedLanguage(newValue)
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      name="selectedLanguage"
                      variant="outlined"
                      label="Select Language"
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item md={2} xs={2} >
                <Button color="primary" sx={{ m: 1, fontSize: "12px", fontWeight: "normal" }} variant="contained"
                  onClick={(e) => {
                    localStorage.setItem("selectedPublicLanguage", selectedLanguage.id)
                    localStorage.setItem("selectedPublicCategoryId", selectedCategory.id);
                    setSelectedCategoryId(selectedCategory.id)
                    getBookByCategory(selectedCategory.id, selectedLanguage.id)
                  }}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
            <Stack direction="row" sx={{ height: 'calc(100% - 100px)' }} spacing={0}>
              <Box sx={{ flex: '0 0 30%', overflow: 'auto' }}>
                <BookDownloadCategoryTable arrayData={arrayData} selectedDataId={selectedCategoryId} onDelete={deleteConfirmation} onCategoryClick={onCategoryClick} sx={{ borderRight: '1px solid #E1E3E8' }} />
              </Box>
              <Box sx={{ flex: '0 0 70%', overflow: 'auto' }}>
                <BookListTable arrayData={arrayBook} onDelete={onDeleteBookClick} onEdit={onEditBookClick} />
              </Box>
            </Stack>
          </Card>
        </Container>
        {objDeleteConfirmation.deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={objDeleteConfirmation.deleteMessage}
          updateConfirmAlert={(e, value) => {
            seObjDeleteConfirmation(prevState => ({
              ...prevState,
              deleteConfirmationShouldOpen: false
            }));
            updateConfirmAlert(value);
          }} />
        )}
        <Backdrop style={{ zIndex: 1 }} open={isLoading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box >
    </>
  );
}
